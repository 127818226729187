
















































































































































































































































import { InputSetups } from '@/mixins/input-setups'
import { BoardAdmin, Permissions } from '@/includes/types/Board.types'
import { BoardsApi } from '@/includes/Api/Boards.api'
import AdminByLink from '@/components/AddAdminsToBoard/components/AdminByLink.vue'
import AdminByName from '@/components/AddAdminsToBoard/components/AdminByName.vue'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import DrawerWidthMixin from '@/includes/DrawerWidthMixin'
import { GetBoardByIdType } from '@/store/boards/BoardsGettersInterface'

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import AListCustom from 'piramis-base-components/src/components/AListCustom/AListCustom.vue'
import AListCustomItem from 'piramis-base-components/src/components/AListCustom/AListCustomItem.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { tgLoginLink, atSignedLogin } from 'piramis-base-components/src/shared/utils/tgLoginHelpers'
import AddEntityCard from "piramis-base-components/src/components/AddEntityCard.vue";

import Component from 'vue-class-component'
import { Mixins } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  data() {
    return {
      EntityTypes,
      tgLoginLink,
      atSignedLogin
    }
  },
  components: {
    AdminByName,
    AdminByLink,
    AddEntityCard,
    ConfigField,
    AListCustom,
    AListCustomItem
  },
  computed: {
    ...mapGetters([ 'getBoardById' ])
  }
})
export default class Admins extends Mixins<UseFields, InputSetups, DrawerWidthMixin>(UseFields, InputSetups, DrawerWidthMixin) {

  getBoardById!: GetBoardByIdType

  adminDescriptionSidebarOpen = false

  currentAdminAddMethod = 'link'

  isLoading = false

  userType: 'Administrator' | 'Editor' = 'Administrator'

  permissions: Permissions = this.defaultPermissions

  radioInputOptions: Array<SelectOptionData> = [
    {
      label: this.$t('field_user_type_administrator_title').toString(),
      value: 'Administrator',
    },
    {
      label: this.$t('field_user_type_editor_title').toString(),
      value: 'Editor',
    },
  ]

  drawerType: 'Show' | 'Edit' | 'Create' = 'Create'

  selectedAdmin: BoardAdmin | null = null

  channelIds: Array<number | null> = [ null ]

  get boardTargetOptions() {
    return this.$store.getters.boardTargetOptions
  }

  get boardChannels(): Array<SelectOptionData> {
    const allChannelsOption = { label: this.$t('allow_all_channels_option').toString(), value: null }

    if (this.channelIds.includes(null)) {
      return [ allChannelsOption ]
    } else {
      return [
        allChannelsOption,
        ...this.boardTargetOptions
      ]
    }
  }

  get permissionsToSet(): Permissions | null {
    if (this.userType === 'Administrator') return null
    return this.permissions
  }

  get channelIdsToSet(): Array<number> | null {
    if (!this.channelIds.includes(null)) return (this.channelIds as Array<number>)
    return null
  }

  get drawerTitle(): string {
    if (this.drawerType === 'Edit') return this.$t('admin_drawer_mode_edit').toString()
    if (this.drawerType === 'Create') return this.$t('admin_drawer_mode_create').toString()
    if (this.drawerType === 'Show') return this.$t('admin_drawer_mode_show').toString()
    return ''
  }

  get defaultPermissions(): Permissions {
    return {
      create: false,
      hold: false,
      view: false,
      edit: false,
      access_published: false,
      limit_published: false,
    }
  }

  get disabled(): boolean {
    if (this.drawerType === 'Show') {
      this.radioInputOptions.forEach(value => this.$set(value, 'disabled', true))

      return true
    }

    this.radioInputOptions.forEach(value => this.$set(value, 'disabled', false))

    return false
  }

  get admins(): Array<BoardAdmin> {
    return this.$store.state.boardsState.activeBoard?.admins
  }

  handleUpdatedAdminList(admins: Array<BoardAdmin>): void {
    this.adminDescriptionSidebarOpen = false
    this.$store.commit('update_board_admins', admins)
  }

  handleAddEntityCardClick(): void {
    this.drawerType = 'Create'
    this.adminDescriptionSidebarOpen = true
  }

  handleDrawerClose(): void {
    this.adminDescriptionSidebarOpen = false
    this.userType = 'Administrator'
    this.selectedAdmin = null
    this.channelIds = [ null ]

    Object.entries(this.defaultPermissions).forEach(([ key, value ]) => this.$set(this.permissions, key, value))
  }

  updateAdmin(): void {
    this.isLoading = true

    BoardsApi.updateBoardAdmin('tg', {
      channel_ids: this.channelIdsToSet,
      permissions: this.permissionsToSet,
      board_key: this.getBoardById(this.$route.params[ EntityTypes.BOT_ID ])!.board,
      user_id: this.selectedAdmin!.user_id
    })
      .then(response => this.$store.commit('update_board_admins', response.admins))
      .catch(errorNotification)
      .finally(() => {
        this.isLoading = false
        this.adminDescriptionSidebarOpen = false
      })
  }

  openUserToEdit(admin: BoardAdmin): void {
    this.drawerType = 'Edit'
    this.processSelectedUser(admin)
    this.adminDescriptionSidebarOpen = true
  }

  processSelectedUser(admin: BoardAdmin): void {
    this.selectedAdmin = admin

    if (admin.channel_ids !== null) {
      this.channelIds = admin.channel_ids
    } else {
      this.channelIds = [ null ]
    }

    if (admin.limits !== null) {
      this.userType = 'Editor'

      Object.entries(admin.limits).forEach(([ key, value ]) => this.$set(this.permissions, key, value))
    } else {
      this.userType = 'Administrator'
    }
  }

  userPreview(admin: BoardAdmin): void {
    this.drawerType = 'Show'
    this.processSelectedUser(admin)

    this.adminDescriptionSidebarOpen = true
  }

  deleteAdmin(user_id: number): void {
    this.$confirm({
      title: this.$t('remove_admin_warning').toString(),
      content: this.$t('remove_admin_warning_message').toString(),
      okText: this.$t('accept').toString(),
      cancelText: this.$t('reject').toString(),
      onOk: () => {
        this.isLoading = true

        BoardsApi.deleteBoardAdmin('tg', {
          board_key: this.getBoardById(this.$route.params[ EntityTypes.BOT_ID ])!.board,
          user_id
        })
          .then(() => {
            this.$store.dispatch('getBoardConfig', this.getBoardById(this.$route.params[ EntityTypes.BOT_ID ])?.board)
              .then(() => {
                successNotification()
                this.permissions = this.defaultPermissions
              })
              .finally(() => this.isLoading = false)
          })
          .catch(error => {
            errorNotification(error)
            this.isLoading = false
          })
      },
      centered: true
    })
  }
}
