import BaseApi from "@/includes/services/BaseApi";
import { BoardConfig } from '@/includes/types/Board.types'
import { BaseBoardRequest } from "@/includes/types/Api.types";

type BoardLabels = Pick<BoardConfig, 'labels'>

export class LabelsService {
	static createLabel(type: string, body: BaseBoardRequest<{ title: string }>) {
		return BaseApi.sendRequest<BoardLabels & { index: number }>(type, 'createlabel', body)
	}

	static deleteLabel(type: string, body: BaseBoardRequest<{ index: number }>) {
		return BaseApi.sendRequest<BoardLabels>(type, 'deletelabel', body)
	}

	static renameLabel(type: string, body: BaseBoardRequest<{ index: number, title: string }>) {
		return BaseApi.sendRequest<BoardLabels>(type, 'renamelabel', body)
	}
}