

















































import Icon from 'piramis-base-components/src/components/Icon/Icon.vue'

import { Component } from 'vue-property-decorator'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import TokenId from "piramis-base-components/src/shared/utils/TokenId";

@Component({
  computed:{
    ...mapGetters([ "groupedNotifications" ])
  },
  components: {
    Icon
  }
})
export default class SuggessNotifications extends Vue {

  notificationsLoading = false

  gotoSuggestedList(boardId:string): void {
    this.$router.push({
      name: 'posts_planner',
      params: {
        [EntityTypes.BOT_ID]: TokenId.getId(boardId)
      },
      query: {
        calendar: 'suggested'
      }
    }).catch(() => {
    })
  }

  gotoSuggestPost(suggestPost:any):void {
    this.$router.push({
      name: 'post',
      params: {
        [EntityTypes.BOT_ID]: TokenId.getId(suggestPost.board_key),
        actionType: 'show-suggest'
      },
      query: {
        postId: suggestPost.post_key
      }
    })
  }

  groupTitle(boardId:string):string {
    return this.$store.state.boardsState.boards?.find((board) => board.board === boardId).title
  }

  mounted(): void {
    this.notificationsLoading = true

    this.$store.dispatch('requestSuggestNotifications')
      .finally(() => this.notificationsLoading = false)
  }
}
