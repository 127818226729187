


















































































import ChannelLicenseHelper from "@/includes/logic/ChannelLicenseHelper";

import AListCustom from 'piramis-base-components/src/components/AListCustom/AListCustom.vue'
import AListCustomItem from 'piramis-base-components/src/components/AListCustom/AListCustomItem.vue'
import HighlightAnchor from 'piramis-base-components/src/components/HighlightAnchor.vue'

import moment from 'moment'
import Vue from 'vue'
import { Component } from 'vue-property-decorator'

@Component({
  components: {
    AListCustom,
    AListCustomItem,
    HighlightAnchor
  },
  methods: {
    moment
  }
})
export default class ChannelLicenseInformation extends Vue {

  channelLicense = new ChannelLicenseHelper(this.channel)

  get channel() {
    return this.$store.state.channelsState.activeChannel
  }

  get channelLicenseBuyButtonTitle() {
    if (this.channel) {
      if (!this.channel.licence_expired) {
        return this.$t('button_license_prolongate').toString()
      } else {
        return this.$t('button_license_buy').toString()
      }
    }
  }

  get channelLicenseStatus() {
    if (this.channel) {
      if (!this.channel.licence_expired) {
        return this.$t('license_active').toString()
      } else if (this.channelLicense.isLicenseExpires) {
        return this.$t('license_expires').toString()
      } else {
        return this.$t('license_expired').toString()
      }
    }

    return ''
  }

  get licenseStatusColor() {
    if (this.channel) {
      if (!this.channel.licence_expired) {
        return 'text-success'
      } else if (this.channelLicense.isLicenseExpires) {
        return 'text-warning'
      } else {
        return 'text-danger'
      }
    }

    return ''
  }

  get freeLicenseTypeTitle() {
    if (this.channelLicense.hasFreeLicenseByMembersCount) {
      return this.$t('free_license_under_1000').toString()
    }

    return ''
  }
}
