export enum ProjectButtonTypes {
    RunFlowButton = 'RunFlowButton',
    FormAnswerButton = 'FormAnswerButton',
    ShareTextButton = 'ShareTextButton',
    FormButton = 'FormButton',
    PopupButton = 'PopupButton',

    HiddenMessageButton = 'HiddenMessageButton',
    // just for dedicated button -- not really existed type
    SimpleHiddenMessageButton = 'SimpleHiddenMessageButton',

    HiddenCountedMessageButton = 'HiddenCountedMessageButton',
    HiddenCountedLinkButton = 'HiddenCountedLinkButton',
    HiddenSubscriberLinkButton = 'HiddenSubscriberLinkButton',
    LimitedSubscriptionLinkButton = 'LimitedSubscriptionLinkButton',

    CommentsButton = 'CommentsButton'
}