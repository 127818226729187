








import { IntegrationNodeCustom } from "./IntegrationCustom";
import Editor from '@/components/Flow/Editor';
import { ChatkeeperIntegrationSetting, ChatkeeperIntegrationRequest } from "./ChatkeeperIntegration";

import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter';
import TextInput from "piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue";

import { Component, Mixins, Prop } from 'vue-property-decorator'

@Component({
  data() {
    return {
      ChatkeeperIntegrationRequest
    }
  },
  components: {
    ChatkeeperIntegrationSetting,
    SelectInput,
    TextInput
  },
})
export default class IntegrationSidebarCustom extends Mixins(ModelSetter) {
  @Prop() node!: IntegrationNodeCustom

  @Prop() editor!: Editor

}
