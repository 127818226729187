var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chatkeeper-property-setting"},[(_vm.model)?[_c('text-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.model,
          'key': 'token',
          'prefix': 'chatkeeper_data_',
          'validation': 'required',
        },
      }}})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }