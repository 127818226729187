






























































































































































































































































import { InputSetups } from '@/mixins/input-setups'
import PostShopFieldsWrapper from '@/components/Post/PostShopFieldsWrapper.vue'
import SlotsList from '@/components/Slots/SlotsList.vue'
import SaveChannelConfig from '@/components/SaveChannelConfig.vue'
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'
import PostStylesSetup from '@/components/Post/PostStylesSetup.vue'
import { editSlot, init, removeSlot, saveOffer, adStyleFields, TWO_DAYS } from '@/includes/logic/Offers/OffersLogic'
import { Slot } from '@/includes/types/Board.types'
import { FrontendAdvert } from "@/includes/logic/Offers/types";

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'
import MessageEditorWithMediaInput
  from 'piramis-base-components/src/components/Pi/fields/NewMessageEditorWithMediaInput/MessageEditorWithMediaInput.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'
import { tooltipTrigger } from "piramis-base-components/src/shared/utils/tooltipTrigger";
import TimeUnitInput from 'piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue'
import TextInput from 'piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue'
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import EditorInput from 'piramis-base-components/src/components/Pi/fields/EditorInput/EditorInput.vue'
import MiniHelpMessage from 'piramis-base-components/src/components/MiniHelpMessage/MiniHelpMessage.vue'

import { Component, Mixins, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'

@Component({
  methods: { tooltipTrigger },
  components: {
    SaveChannelConfig,
    SlotsList,
    PostShopFieldsWrapper,
    PageTitle,
    ConfigField,
    PostStylesSetup,
    Accordion,
    MessageEditorWithMediaInput,
    TimeUnitInput,
    TextInput,
    SelectInput,
    EditorInput,
    MiniHelpMessage
  },
  data() {
    return {
      EntityTypes,
      adStyleFields,
      TWO_DAYS
    }
  }
})
export default class OffersListItem extends Mixins<InputSetups, ModelSetter>(InputSetups, ModelSetter) {

  offer: FrontendAdvert | null = null

  @Watch('offer', { deep: true })
  onAdvertChange(advert: FrontendAdvert | null) {
    if (advert && this.offer) {
      if (advert.expire !== undefined && advert.expire > TWO_DAYS && (advert.replace_message === undefined || advert.replace_message === null)) {
        this.$set(this.offer, 'replace_message', {
          attachments: [],
          text: '',
          buttons: [],
          remove_previous: false,
          pin: false,
          disable_link_preview: false,
          disable_notify: false,
        })
      }
    }
  }

  get styleId() {
    return this.offer?.style?.id ?? null
  }

  set styleId(id: number | null) {
    if (this.offer) {
      if (id === null) {
        this.offer.style = null
      } else {
        this.$set(this.offer, 'style', cloneDeep(this.$store.getters.findStyle(id)))
      }
    }
  }

  get styleOptions() {
    return [
      {
        label: this.$t('advert_style_none').toString(),
        value: null,
      },
      ...this.$store.getters.styleOptionsIds
    ];
  }

  get selectedStyle() {
    return this.styleOptions.find(s => s.value === this.styleId)
  }

  get adId() {
    return Number.parseInt(this.$route.params.adId)
  }

  get saveConfigButtonDisabled() {
    return this.offer
      && (
        !this.offer.name.length
        || !this.offer.rules.length
        || !this.offer.targets.length
        || !this.offer.plan.length
        || this.offer.cash_register_id === null
        || this.offer.product_id === null
      )
  }

  onEditSlot(payload: Slot & { index?: number }) {
    if (this.offer) {
      this.offer = editSlot(this.offer, payload)
    }
  }

  onRemoveSlot(slotIndex: number) {
    if (this.offer) {
      this.offer = removeSlot(this.offer, slotIndex)
    }
  }

  onSaveClick() {
    if (this.offer) {
      saveOffer(this.offer, this.$route.params.actionType as BaseItemViewAction)
    }
  }

  created() {
    if (this.$route.params.actionType === BaseItemViewAction.Edit) {
      this.offer = init(this.adId)
    } else {
      this.offer = init()
    }

    if (!this.offer) {
      this.$router.push({
        name: 'OffersList'
      })
    }
  }
}
