import { ChatkeeperRequestTypeEnum } from "@/components/Flow/Nodes/Additionally/IntegrationCustom/ChatkeeperIntegration/types";
import { ChangeAchievementsRequest } from "./ChangeAchievementsRequest";
import { ChatkeeperIntegrationRequestBuilder } from "../IntegrationRequest/ChatkeeperIntegrationRequestBuilder";

import { plainToInstance } from "class-transformer";

export const ChangeAchievementsRequestBuilder = new class ChangeAchievementsRequestBuilder extends ChatkeeperIntegrationRequestBuilder {
    type: ChatkeeperRequestTypeEnum = ChatkeeperRequestTypeEnum.ChangeAchievements;

    settings = () => import('./ChangeAchievementsRequestSetting.vue')
    preview = () => import('./ChangeAchievementsRequestPreview.vue')

    getModel() {
        return plainToInstance(ChangeAchievementsRequest, {
            type: ChatkeeperRequestTypeEnum.ChangeAchievements,
            token: '',
            achievements: [],
            mode: 'Add',
        })
    }
}
