


























































































































































































































































































































































































































import PostTargetSelect from '@/components/Post/PostTargetSelect.vue'
import {
  Conditions,
  CreatedPost,
  MessageVariants,
  PostActionType,
  RequestPost,
  RequestPostDraft,
  ScheduleType,
  Style
} from '@/includes/types/Post.types'
import { InputSetups } from '@/mixins/input-setups'
import PeriodSettings from '@/components/Post/Period/PeriodSettings.vue'
import PostStylesSetup from '@/components/Post/PostStylesSetup.vue'
import Quizes from '@/components/Quizes.vue'
import { PostService } from '@/includes/services/PostService'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import PostTime from '@/components/Post/PostTime.vue'
import { ChannelInfo, ChannelSlotWithGuid } from '@/includes/types/Board.types'
import { canCreatePost, canPostNow } from '@/includes/PermissionHelper'
import SuggestPostActionButton from '@/components/SuggestPostActionButton.vue'
import { LimitedSubscriptionType } from '@/includes/types/Channel.types'
import PostShopFieldsWrapper from '@/components/Post/PostShopFieldsWrapper.vue'
import { CalendarPostsSource } from '@/includes/types/CalendarPostsSource'
import validateActions from '@/components/Post/logic/ValidateActions'
import PostBotState from '@/components/PostBotState.vue'
import PostEditorMessage from '@/components/HelpMessages/editor/PostEditorMessage.vue'
import LimitedSubscriptionLinkButtonBuilder from '@/components/ProjectButtons/LimitedSubscriptionLinkButton'
import { defaultStyleModel } from '@/includes/logic/Style/constant'
import { formatPostSlotTime } from "@/includes/logic/Slots/utils";
import SlotItem from "@/components/Slots/SlotItem.vue";
import ShowMoreSlots from "@/components/Slots/ShowMoreSlots.vue";
import SelectPostReaction from '@/components/Post/NewActions/components/PostReaction/SelectPostReaction.vue'
import SelectPostAction from '@/components/Post/NewActions/components/PostAction/SelectPostAction.vue'
import NewActionsList from "@/components/Post/NewActions/components/mixins/NewActionsList";

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'
import { MessageEditorWithMediaTab } from 'piramis-base-components/src/components/NewMessageEditors/types'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import isMobile from 'piramis-js-utils/lib/isMobile'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import MultiMessageEditorWithMediaInput
  from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'
import {
  IPostMessage,
  PayedPostMessage,
  PollPost,
  PostType,
  QuizPost,
} from 'piramis-base-components/src/shared/modules/posting/types'
import TelegramPostPreview from 'piramis-base-components/src/shared/modules/posting/PostPreview/TelegramPostPreview.vue'

import { Mixins, Watch } from 'vue-property-decorator'
import { cloneDeep, upperFirst } from 'lodash'
import Component from 'vue-class-component'
import moment from 'moment'
import { Guid } from 'guid-typescript'
import axios from 'axios'

Component.registerHooks([
  'beforeRouteLeave'
])

@Component({
  components: {
    SelectPostReaction,
    SelectPostAction,
    ShowMoreSlots,
    SlotItem,
    MultiMessageEditorWithMediaInput,
    PostShopFieldsWrapper,
    SuggestPostActionButton,
    PostTargetSelect,
    Quizes,
    PeriodSettings,
    Accordion,
    PostStylesSetup,
    PostTime,
    PostBotState,
    TelegramPostPreview
  },
  data() {
    return {
      MessageEditorWithMediaTab,
      ScheduleType,
      PostEditorMessage,
      EntityTypes,
      PostType
    }
  },
  methods: {
    moment,
    formatPostSlotTime,
    canCreatePost,
    canPostNow,
  },
})
export default class Post extends Mixins(ModelSetter, InputSetups, UseFields, NewActionsList) {
  hasChannelsContentSubscription = false

  existedStyle = false

  postType: PostType = PostType.Post

  isPostLoaded = false

  scheduleType = '' as ScheduleType

  hasPostActions = false

  hasPostReactions = false

  slots: Array<ChannelSlotWithGuid> | null = null

  selectSlotModalOpen = false

  isCreateButtonDisabled = true

  periodDisabled = false

  leaveRouteModalIsOpen = false

  get boardTargetOptions() {
    return this.$store.getters.boardTargetOptions
  }

  modelPost(): any {
    if (this.$route.query.type !== PostType.Paid.toLowerCase()) {
      return {
        message: {} as IPostMessage | PollPost | QuizPost,
        delayed_actions: [],
        reaction_actions: [],
      }
    } else {
      return {
        delayed_actions: [],
        reaction_actions: [],
        message: {
          type: PostType.Paid,
          variants: [],
          product_id: null,
          cash_register_id: null
        } as PayedPostMessage
      }
    }
  }

  model: RequestPost = {
    post: {
      ...this.modelPost(),
    },
    description: '',

    period: {
      conditions: [],
      unit: 'MINUTES',
      interval: 0
    },
    time: moment().add(1, 'd').add(1, 'h').startOf('hour').format('YYYY-MM-DD HH:mm:ss'),
    timezone: '',

    targets: [],
    origin: null,
    style: { ...cloneDeep(defaultStyleModel), disable_notify: true },
    tags: [],
  }

  isLoading = false

  runtimeType: 'Slot' | 'Custom' = 'Custom'

  slotPublishTo: ChannelSlotWithGuid | null = null

  styleBackup = {} as Style

  isPreviewModalActive = false

  isPreviewVisible = true

  @Watch('model.post.message.variants')
  onMediaEditorChange(value: Array<MessageVariants>) {
    if (value) {
      this.isCreateButtonDisabled = true
      value.forEach((m: MessageVariants) => {
        if (!m.text && m.attachments && !m.attachments.length || m.text === '\n') {
          this.isCreateButtonDisabled = true
        } else if (m.attachments && (m.text || m.attachments.length)) {
          this.isCreateButtonDisabled = false
        } else {
          this.isCreateButtonDisabled = false
        }
      })
    }
  }

  @Watch('$screen.lg', { immediate: true })
  onScreenLgChange(state: boolean) {
    if (!state) {
      this.isPreviewVisible = false
    } else {
      this.isPreviewModalActive = false
    }
  }

  get previewButtonIcon(): 'eye-invisible' | 'eye' {
    if (this.isPreviewVisible || this.isPreviewModalActive) {
      return 'eye-invisible'
    } else {
      return 'eye'
    }
  }

  getSlots(start = 0, end?: number) {
    if (this.slots) {
      return this.slots.slice(start, end)
    }

    return []
  }

  checkPostPreview(signal: AbortController['signal']) {
    return PostService.getPostPreview('tg', {
                                        board_key: this.$store.state.boardsState.activeBoard.board,
                                        post: this.model.post.message,
                                        style: this.model.style,
                                        apply_watermark: true
                                      },
                                      { signal }
    )
      .then(res => res.post)
      .catch((error) => {
        if (!axios.isCancel(error)) {
          errorNotification(error)
        }
      })
  }

  get baseValidation(): boolean {
    return !this.model.targets!.length || this.periodDisabled
  }

  get mainFieldsQuizValidation(): boolean {
    if (this.model.post.message.type === PostType.Quiz || this.model.post.message.type === PostType.Poll) {
      return !this.model.post.message.questions.length ||
        !this.model.post.message.text ||
        this.model.post.message.open_period! > 600
    }
    return true
  }

  get dropdownScheduleTypes(): Array<{ key: string, icon: string, action: ScheduleType }> {
    return [
      {
        'key': 'post_publish_test',
        'icon': 'tool',
        'action': ScheduleType.Test
      },
      {
        'key': 'post_save_draft',
        'icon': 'folder',
        'action': ScheduleType.Draft,
      }
    ]
  }

  get postPageActionType(): 'new' | 'copy' | 'edit' | 'edit-published' | 'show-suggest' | 'edit-suggest' {
    return this.$route.params.actionType as 'new' | 'copy' | 'edit' | 'edit-published' | 'show-suggest' | 'edit-suggest'
  }

  get styleAccordionTitle(): string {
    if (this.model.style && this.model.style.id) {
      return this.$t('watch_selected_style_slider', [ this.styleNameById(this.model.style.id) ]).toString()
    }

    return this.$t('watch_style_settings_empty').toString()
  }

  get postCardTranslation(): string {
    if ([ 'new', 'copy' ].includes(this.postPageActionType) && !this.$route.query.suggest) {
      return this.$t(`field_create_new_${ this.postType!.toLowerCase() }_title`).toString()
    } else if (this.postPageActionType === 'edit-published') {
      return this.$t(`field_edit_published_post_title`).toString()
    } else if (this.postPageActionType === 'show-suggest') {
      return this.$t('field_show_suggested_post_title').toString()
    } else if (this.postPageActionType === 'edit-suggest') {
      return this.$t('field_edit_suggested_post_title').toString()
    } else if (this.$route.query.suggest) {
      return this.$t('field_suggest_new_post_title').toString()
    } else {
      return this.$t(`field_edit_${ this.postType!.toLowerCase() }_title`).toString()
    }
  }

  get hasPinActions() {
    return this.model.post.delayed_actions?.some(a => a.action.type === PostActionType.PinMessageAction) ||
      this.model.post.reaction_actions?.some(a => a.action.type === PostActionType.PinMessageAction)
  }

  get initializeVariants(): Array<MessageVariants> {
    return [ {
      attachments: [],
      text: '',
      buttons: [],
      remove_previous: false,
      pin: false,
      disable_link_preview: false,
      disable_notify: false,
      protect_content: false,
      remove_after: 0,
      send_after: 0
    } ]
  }

  get channelsToPost(): Array<SelectOptionData> {
    return this.boardTargetOptions.filter(value => this.model.targets.includes(value.value))
  }

  get postMessageButtons() {
    if (this.postType === PostType.Paid) {
      return this.newPmButtons
    }

    return this.getNewDefaultButtons({
      moreProButtons: [
        new LimitedSubscriptionLinkButtonBuilder(
          this.$i18n,
          this.getArticlesSelectOptions,
          () => this.hasChannelsContentSubscription
        )
      ]
    })
  }

  get postTypeHelpMessageKey() {
    return `${ this.postType?.toLowerCase() }_post_type_help_message`
  }

  goToSlotsSettings(): void {
    this.$router.push({
      name: 'Publish_settings',
      params: {
        [EntityTypes.BOT_ID]: this.$route.params[EntityTypes.BOT_ID],
        [EntityTypes.CHANNEL_ID]: this.model.targets[0].toString()
      },
      hash: '#slots'
    })
  }

  activeSlotButtonClasses(slot: ChannelSlotWithGuid): Record<string, boolean> {
    return {
      'active': !!this.slotPublishTo && slot.guid === this.slotPublishTo.guid
    }
  }

  handleEditSuggestedPostButton(): void {
    PostService.deleteSuggestPost('tg', {
      board_key: this.$store.getters.activeBoard!.board,
      post_key: this.$route.query.postId.toString()
    })
      .then(() => {
        this.suggestPost()
      })
      .catch(errorNotification)
  }

  handlePreviewClick(): void {
    if (isMobile() || !this.$screen.lg) {
      this.isPreviewModalActive = true
    } else {
      this.isPreviewVisible = !this.isPreviewVisible
    }
  }

  onSlotClick(slot: ChannelSlotWithGuid): void {
    this.runtimeType = 'Slot'

    this.slotPublishTo = slot
    this.model.time = slot.date + ' ' + slot.slot.time

    this.selectSlotModalOpen = false
  }

  styleNameById(styleId: number | undefined): string {
    if (this.$store.state.boardsState.activeBoard?.config.styles !== null) {
      for (const [ name, style ] of Object.entries(this.$store.state.boardsState.activeBoard?.config.styles)) {
        if ((style as Style).id === Number(styleId)) {
          return name
        }
      }
      return ''
    }
    return ''
  }

  initializePostMessage(): void {
    const postQuestionaire = {
      type: this.postType,
      text: '',
      open_period: 0,
      is_anonymous: true,
      questions: [],
    }
    if (this.postType === PostType.Poll) {
      this.model.post.message = { ...postQuestionaire, allows_multiple_answers: true } as PollPost
    }
    if (this.postType === PostType.Quiz) {
      this.model.post.message = { ...postQuestionaire, correct_option_id: '', explanation: '' } as QuizPost
    }
    if (this.postType && [ PostType.Post, PostType.Paid ].includes(this.postType)) {
      this.$set(this.model.post.message, 'variants', this.initializeVariants)
      this.model.post.message.type = this.postType
    }
  }

  hasAdSlot(slots: Array<ChannelSlotWithGuid>): ChannelSlotWithGuid | undefined {
    return slots.find(s => s.slot.ads_only)
  }

  regularSlot(slots: Array<ChannelSlotWithGuid>): ChannelSlotWithGuid | undefined {
    return slots.find(s => !s.slot.ads_only)
  }

  handleTargetsChange(targets: Array<SelectOptionData>): void {
    const targetIds = targets.map(t => t.value)

    const boardTargets = this.$store.getters.activeBoardChannels.filter(target => targetIds.includes(target.id))

    this.hasChannelsContentSubscription = !!boardTargets.length && boardTargets.every((t: Omit<ChannelInfo, 'config'>) => t.limited_subscription_type === LimitedSubscriptionType.Content)
  }

  setSlotDateTime(slots: Array<ChannelSlotWithGuid> | null): void {
    if (slots) {
      this.slots = slots.map(slot => {
        this.$set(slot, 'guid', Guid.create().toString())

        return slot
      })
    }

    if (slots === null) {
      this.slotPublishTo = null
      this.runtimeType = 'Custom'
      this.slots = []
    } else {
      if (this.postPageActionType === 'new' && this.runtimeType === "Slot") {
        // this.runtimeType = 'Slot'

        const adSlot = this.hasAdSlot(slots)
        const regularSlot = this.regularSlot(slots)

        if (this.styleBackup) {
          if (this.styleBackup.data === undefined || this.styleBackup.data === null) {
            if (slots[0].slot.ads_only) {
              this.slotPublishTo = regularSlot ?? null
            } else {
              this.slotPublishTo = slots[0]
            }
          }

          if (this.styleBackup.data) {
            if (this.styleBackup.data.post_price > 0 && !slots[0].slot.ads_only) {
              if (adSlot) {
                this.slotPublishTo = adSlot
              } else {
                this.slotPublishTo = regularSlot ?? null
              }
            }

            if (this.styleBackup.data.post_price > 0 && slots[0].slot.ads_only) {
              this.slotPublishTo = slots[0]
            }

            if (this.styleBackup.data.post_price <= 0 && slots[0].slot.ads_only) {
              this.slotPublishTo = regularSlot ?? null
            }

            if (this.styleBackup.data.post_price <= 0 && !slots[0].slot.ads_only) {
              this.slotPublishTo = slots[0]
            }
          }
        }

        if (this.slotPublishTo !== null) {
          // this.model.schedule.run_time = this.slotPublishTo?.date + ' ' + this.slotPublishTo?.slot.time
        } else {
          this.slotPublishTo = null
          this.runtimeType = 'Custom'
        }
      }
    }
  }

  suggestPost(): void {
    this.isLoading = true
    this.preparePostData()
      .then(data => this.validatePost(data))
      .then(validatedPost => {
        PostService.suggestPost('tg', validatedPost)
          .then((post) => {
            successNotification()

            this.gotoPlannerPage({
              calendar: CalendarPostsSource.Suggested,
              date: moment(post.post.schedule.run_time).format('YYYY-MM-DD')
            })
          })
          .catch((e) => {
            errorNotification(e)
          })
          .finally(() => {
            this.isLoading = false
          })
      })
      .catch(errorNotification)
  }

  handleDropdownItem(action: ScheduleType): void {
    this.scheduleType = action

    if (action === ScheduleType.Suggest) {
      this.suggestPost()
    }
    if (action === ScheduleType.PostNow) {
      if (this.postPageActionType === 'edit') {
        this.editPost()
      }
      if (this.postPageActionType === 'new' || this.postPageActionType === 'copy') {
        this.createPost()
      }
    }
    if (action === ScheduleType.Test) {
      this.testPost()
    }
    if (action === ScheduleType.Draft) {
      this.savePost()
    }
  }

  testPost(): void {
    PostService.testPost('tg', {
      board_key: this.$store.getters.activeBoard!.board,
      post: this.getPost().message,
      style: this.model.style
    })
      .then(() => {
        successNotification()
      })
      .catch(errorNotification)
      .finally(() => this.scheduleType = '' as ScheduleType)

  }

  savePost(): Promise<void> {
    return new Promise(resolve => {
      this.isLoading = true
      this.preparePostData()
        .then((validatedPost) => {
          this.$store.dispatch('createDraft', validatedPost)
            .then((post) => {
              this.isNotSave = false

              if (post) {
                successNotification()

                this.gotoPlannerPage()
              }
            })
            .finally(() => {
              this.isLoading = false
            })
        })
        .catch(errorNotification)

      resolve()
    })
  }

  gotoPlannerPage(query?: Record<string, string>): void {
    this.$router.push({
      name: 'posts_planner',
      params: {
        [EntityTypes.BOT_ID]: this.$route.params[EntityTypes.BOT_ID],
      },
      query: { ...query }
    })
  }

  editPost() {
    this.isLoading = true

    this.preparePostData()
      .then(this.validatePost)
      .then(validatedPost => {
        const postToEdit = {
          post_key: this.$route.query.postId,
          ...validatedPost
        }

        PostService.editPost('tg', postToEdit)
          .then((post) => {
            successNotification()

            this.isNotSave = false

            this.gotoPlannerPage({
              calendar: CalendarPostsSource.Schedule,
              date: moment(post.post.schedule.run_time).format('YYYY-MM-DD')
            })
          })
          .catch((e) => {
            errorNotification(e)
          })
          .finally(() => {
            this.isLoading = false
          })
      })
  }

  validatePost(postData: RequestPost | RequestPostDraft | Omit<RequestPost, 'schedule'>): Promise<RequestPost | RequestPostDraft | Omit<RequestPost, 'schedule'>> {
    return new Promise((resolve, reject) => {
      if (this.slotPublishTo !== null && this.runtimeType === 'Slot') {
        const isSlotAd = this.slotPublishTo.slot.ads_only

        if (this.styleBackup && this.styleBackup.data) {
          const postPrice = this.styleBackup.data.post_price

          if (isSlotAd && postPrice <= 0) {
            reject(this.$t('ads_only_post'))
          }

          if (!isSlotAd && postPrice > 0) {
            reject(this.$t('regular_only_posts'))
          }
        }
        if (this.styleBackup.data === undefined || this.styleBackup.data === null) {
          if (isSlotAd) {
            reject(this.$t('ads_only_post'))
          }
        }
      }

      const post = postData as RequestPost

      if (post.post.message.type === PostType.Poll || post.post.message.type === PostType.Quiz) {
        if (post.post.message.questions.length < 2) {
          reject(this.$t('poll_questions_min_values'))
        }
      }

      if (post.post.message.type === PostType.Paid) {
        if (post.post.message.product_id === null || post.post.message.cash_register_id === null) {
          reject(this.$t('paid_post_fill_shop_fields'))
        }
      }

      if (post.post.message.type === PostType.Post || post.post.message.type === PostType.Quiz || post.post.message.type === PostType.Poll) {
        if (post.post.delayed_actions && post.post.delayed_actions.length) {
          const result = validateActions(post.post.delayed_actions)

          if (result) resolve(postData)
          reject(this.$t('actions_are_not_valid_error'))
        }

        if (post.post.reaction_actions && post.post.reaction_actions.length) {
          const result = validateActions(post.post.reaction_actions)

          if (result) resolve(postData)
          reject(this.$t('reactions_are_not_valid_error'))
        }
      }

      resolve(postData)
    })
  }

  createPost(): void {
    this.isLoading = true
    this.preparePostData()
      .then(this.validatePost)
      .then(validatedPost => {
        PostService.createPost('tg', validatedPost)
          .then((post) => {
            successNotification()

            this.isNotSave = false

            this.gotoPlannerPage({
              calendar: CalendarPostsSource.Schedule,
              date: moment(post.post.schedule.run_time).format('YYYY-MM-DD')
            })
          })
          .catch((e) => {
            errorNotification(e)
          })
          .finally(() => {
            this.isLoading = false
          })
      })
      .catch(errorNotification)
      .finally(() => this.isLoading = false)
  }

  preparePostData(): Promise<RequestPost | RequestPostDraft> {
    return new Promise(resolve => {
      let postData: RequestPost | RequestPostDraft | Omit<RequestPost, 'schedule'>

      if (this.scheduleType === ScheduleType.Draft) {
        postData = {
          board_key: this.$store.getters.activeBoard!.board,
          message: { ...this.getPost().message },
          description: this.model.description
        }
      } else if (this.scheduleType === ScheduleType.Test) {
        postData = {
          board_key: this.$store.getters.activeBoard!.board,
          targets: this.model.targets,
          post: this.getPost(),
          style: this.model.style,
          origin: this.model.origin,
          description: this.model.description
        }
      } else if (this.scheduleType === ScheduleType.PostNow) {
        postData = {
          board_key: this.$store.getters.activeBoard!.board,
          targets: this.model.targets,

          period: this.model.period?.interval && this.model.period.interval >= 0 ? this.model.period : null,

          post: this.getPost(),
          style: this.model.style,
          origin: this.model.origin,
          description: this.model.description
        }
      } else {
        postData = {
          board_key: this.$store.getters.activeBoard!.board,
          targets: this.model.targets,
          time: this.model.time,
          timezone: this.model.timezone,

          period: this.model.period?.interval && this.model.period.interval >= 0 ? this.model.period : null,

          post: this.getPost(),
          style: this.model.style,
          origin: this.model.origin,
          description: this.model.description

        }
      }

      resolve(postData)
    })
  }

  getConditions(): Array<Conditions> | null {
    return this.model?.period?.conditions ? this.model!.period!.conditions : null
  }

  getPost(): CreatedPost['post'] {
    const { type } = this.model.post.message

    let resPostObject = {
      message: this.model.post.message,
      delayed_actions: this.model.post.delayed_actions,
      reaction_actions: this.model.post.reaction_actions,
    }

    if (type === PostType.Paid) {
      resPostObject.message = {
        ...resPostObject.message,
        cash_register_id: this.model.post.message.cash_register_id,
        product_id: this.model.post.message.product_id,
        variants: this.model.post.message.variants
      } as PayedPostMessage
    }

    if (type === PostType.Poll || type === PostType.Quiz) {
      const { open_period } = (resPostObject.message as PollPost | QuizPost)

      ;(resPostObject.message as PollPost | QuizPost).open_period = open_period === 0 ? null : open_period
    }

    return resPostObject
  }

  isButtonDisabled(scheduleType?: ScheduleType): boolean {
    if (scheduleType === ScheduleType.Draft || scheduleType === ScheduleType.Test) {
      if (this.model.post.message.type === PostType.Poll) {
        return this.mainFieldsQuizValidation
      }
      if (this.model.post.message.type === PostType.Quiz) {
        return this.mainFieldsQuizValidation || !this.model.post.message.correct_option_id
      }
      return this.isCreateButtonDisabled
    } else {
      if (this.model.post.message.type === PostType.Poll) {
        return this.baseValidation || this.mainFieldsQuizValidation
      }
      if (this.model.post.message.type === PostType.Quiz) {
        return this.baseValidation || this.mainFieldsQuizValidation! || !this.model.post.message.correct_option_id
      }
      return this.baseValidation || this.isCreateButtonDisabled
    }
  }

  handleSaveButtonClick(): void {
    this.scheduleType = ScheduleType.Schedule

    if ([ 'new', 'copy' ].includes(this.postPageActionType)) {
      this.createPost()
    } else if (this.postPageActionType === 'edit-published') {
      this.editPublishedPost()
    } else {
      this.editPost()
    }
  }

  editPublishedPost(): void {
    this.isLoading = true
    const postBody = {
      board_key: this.$store.getters.activeBoard!.board,
      post_key: this.$route.query.postId.toString(),
      message: (this.model.post.message as IPostMessage).variants,
      style: this.model.style
    }

    PostService.editPublishedPost('tg', postBody)
      .then(() => {
        successNotification()

        this.gotoPlannerPage()
      })
      .catch(errorNotification)
      .finally(() => {
        this.isLoading = false
      })
  }

  setPostType(type: PostType): void {
    switch (type) {
      case PostType.Quiz:
        this.postType = PostType.Quiz
        break
      case PostType.Poll:
        this.postType = PostType.Poll
        break
      case PostType.Post:
        this.postType = PostType.Post
        break
      case PostType.Paid:
        this.postType = PostType.Paid
        break
      default:
        throw new Error(`Unknown post type: ${ type }`)
    }
  }

  findPostAndSetModel(): void {
    const draft = this.$store.state.draftsState.drafts!.find((p: any) => p.key.token_id === Number(this.$route.query.draft))
    this.setPostType(draft.message.type)
    this.model.post.message = { ...draft.message }

    this.isLoading = false
    this.isPostLoaded = true
  }

  async getSavedPosts() {
    if (!this.$store.state.draftsState.drafts) {
      await this.$store.dispatch('requestDrafts')
    }

    this.findPostAndSetModel()
  }

  getPostById(): void {
    PostService.getOriginalPost('tg', {
      board_key: this.$store.getters.activeBoard!.board,
      post_key: this.$route.query.postId.toString()
    })
      .then((data) => {
        const originalPost = data.post

        this.model.time = originalPost.schedule.run_time

        const { message } = originalPost.message
        const { target, description } = originalPost

        // set post type
        this.setPostType(message.type)
        // description
        this.model.description = description

        // set post message variants
        this.model.post.message = { ...message }

        if (originalPost.message.message.type !== PostType.Paid && this.model.post.message.type !== PostType.Paid) {
          // set post actions if exists
          if (originalPost.message.delayed_actions && originalPost.message.delayed_actions.length) {
            this.model.post.delayed_actions = originalPost.message.delayed_actions
            this.hasPostActions = true
          }

          // set post reactions if exists
          if (originalPost.message.reaction_actions && originalPost.message.reaction_actions.length) {
            this.model.post.reaction_actions = originalPost.message.reaction_actions
            this.hasPostReactions = true
          }
        }

        if ((message.type === PostType.Poll || message.type === PostType.Quiz) && (this.model.post.message.type === PostType.Poll || this.model.post.message.type === PostType.Quiz)) {
          this.model.post.message.open_period = message.open_period === null ? 0 : message.open_period
        }

        // set post timezone and period if exists
        this.model.timezone = originalPost.schedule.timezone

        if (originalPost.schedule.period) {
          this.model.period = originalPost.schedule.period
        }

        // set post targets and origin groups if exists
        this.model.targets = target.targets
        if (target.original) {
          this.model.origin = target.original
        }

        this.model.style = originalPost.style
        this.styleBackup = cloneDeep(originalPost.style)
      })
      .catch(errorNotification)
      .finally(() => {
        this.isPostLoaded = true
        this.isLoading = false
      })
  }

  initializeSuggestedPost(): void {
    if (this.postPageActionType === 'show-suggest') {
      this.disableFields = true
    }

    PostService.getSuggestPost('tg', {
      post_key: this.$route.query.postId.toString(),
      board_key: this.$store.getters.activeBoard!.board,
    })
      .then(({ data }) => {
        this.setPostType(data.post.message.type)
        this.model = data as RequestPost
      })
      .catch(errorNotification)
      .finally(() => {
        this.isPostLoaded = true
      })
  }

  disableFields = false

  mounted(): void {
    const boardTargets = this.$store.getters.activeBoardChannels

    this.$store.commit('updateValidateCommentButtonHasGroup', true)

    if (this.$route.query.type) this.postType = this.model.post.message.type = upperFirst(this.$route.query?.type?.toString()) as PostType
    if (this.$route.query.channel) this.model.targets.push(Number.parseInt(this.$route.query.channel.toString()))

    this.model.timezone = this.$store.getters.activeBoard!.timezone
    if (boardTargets.length === 1) this.model.targets = [ boardTargets[0].id ]

    const styleId = this.$route.query.styleId?.toString()

    if (styleId) {
      this.model.style = cloneDeep(this.$store.state.boardsState.activeBoard?.config.styles[styleId])

      if (this.model.style.data && this.model.style.data.targets && this.model.style.data.targets.length) {
        this.model.targets = [ ...this.model.style.data.targets ]
      }

      this.styleBackup = cloneDeep(this.$store.state.boardsState.activeBoard?.config.styles[styleId])
    }

    if (this.$route.query.draft) {
      this.isLoading = true
      this.getSavedPosts()
    } else if ([ 'edit', 'edit-published', 'copy' ].includes(this.postPageActionType) && this.$route.query.postId) {
      this.isLoading = true
      this.getPostById()
    } else if ([ 'show-suggest', 'edit-suggest' ].includes(this.postPageActionType) && this.$route.query.postId) {
      this.initializeSuggestedPost()
    } else {
      this.initializePostMessage()

      this.isPostLoaded = true
    }
  }

  isNotSave = true

  beforeRouteLeave(flow, to, next): void {
    if (!this.isButtonDisabled(ScheduleType.Test) && this.isNotSave && ![ 'edit', 'edit-suggest', 'edit-published' ].includes(this.postPageActionType)) {
      this.leaveRouteModalIsOpen = true

      this.leaveRouteAndSave = () => {
        this.scheduleType = ScheduleType.Draft
        this.savePost()
          .then(() => {
            next()
          })
      }

      this.leaveRouteNotSave = () => next()
    } else {
      next()
    }
  }

  leaveRouteNotSave = () => {
  }

  leaveRouteAndSave = () => {
  }
}
