


















import { RunTriggerRequest } from "./RunTriggerRequest";
import { InputSetups } from "@/mixins/input-setups";
import { ChatkeeperConfig } from "../../types";

import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";
import { SelectOption } from "piramis-base-components/src/logic/types";

import { Component, Mixins, Prop, VModel, Watch } from 'vue-property-decorator';

@Component({
  components: {
    SelectInput
  }
})
export default class ChangeAchievementsRequestSetting extends Mixins(InputSetups) {
  @VModel() model!: RunTriggerRequest

  @Prop() config!: ChatkeeperConfig | null

  @Watch('config', { immediate: true, deep: true })
  onConfigChange(config: ChatkeeperConfig | null) {
    this.triggersOptions = (config?.triggers ?? []).map(a => {
      return {
        label: a.name,
        value: a.id
      }
    })

  }

  triggersOptions: Array<SelectOption> = []
}
