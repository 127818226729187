import IntegrationSampleNodeCustom from "./IntegrationSampleNodeCustom.vue";
import IntegrationSidebarCustom from "./IntegrationSidebarCustom.vue";
import {
    ChatkeeperIntegrationRequest,
    transformerSubtypes,
    ChatkeeperRequestTypeEnum,
    ChatkeeperIntegrationProxy
} from "./ChatkeeperIntegration";

import { CustomNode, NodeTypes } from "piramis-base-components/src/components/Flow/new/logic/Nodes";
import IntegrationNodeBuilder, {
    IntegrationNode,
    IntegrationNodeData
} from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Additionally/Integration/logic/IntegrationNode'
import { BaseNodeModel } from "piramis-base-components/src/components/Flow/new/logic/Nodes/BaseNode/BaseNodeModel";

import { plainToInstance, Transform } from "class-transformer";
import VueI18n from "vue-i18n";

export default class IntegrationNodeBuilderCustom extends IntegrationNodeBuilder {

    sidebarComponent = IntegrationSidebarCustom;

    constructor(i18n: VueI18n) {
        super(i18n, IntegrationSampleNodeCustom, IntegrationNodeCustom);
    }

    getNodeData(): IntegrationNodeDataCustom {
        return plainToInstance(IntegrationNodeDataCustom, {
            type: NodeTypes.Integration,
            source: 1,
            data: ChatkeeperIntegrationProxy.builders[ChatkeeperRequestTypeEnum.SendMessage].getModel(),
            fail_output: CustomNode.LastSocketId += 1,
        })
    }
}

export class IntegrationNodeCustom extends IntegrationNode {
    declare data: IntegrationNodeDataCustom

    currentIntegration: ChatkeeperIntegrationProxy | undefined;

    getModel(): BaseNodeModel {
        return {
            data: {
                ...this.data,
                data: this.currentIntegration?.getData()
            } as IntegrationNodeDataCustom,
            x: this.position[0],
            y: this.position[1],
            color: this.color,
            ...this.mainInput ? { input: Number(this.mainInput) } : {},
            ...this.mainOutput ? { output: Number(this.mainOutput) } : {},
        }
    }

    constructor(name: NodeTypes.Integration, model: BaseNodeModel) {
        super(name, model);

        if (model.data instanceof IntegrationNodeDataCustom) {
            if (model.data?.source === undefined || model.data?.source === 1) {
                this.currentIntegration = new ChatkeeperIntegrationProxy(model.data.data)
            }
        }
    }
}

export class IntegrationNodeDataCustom extends IntegrationNodeData {
    declare type: NodeTypes.Integration

    source!: number

    @Transform(({ obj, value }) => {
        if (obj?.source === undefined || obj?.source === 1) {
            const m = transformerSubtypes.find(m => m.name === value?.type)

            if (m) {
                return plainToInstance(m.value, value)
            }
        }

        return value
    })
    declare data: ChatkeeperIntegrationRequest
}
