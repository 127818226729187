import InviteLinksApi from "@/includes/Api/InviteLinks.api";
import { errorNotification } from "@/includes/services/NotificationService";
import {
  InviteLinkStatReq,
  InviteLinksWithStat,
  InviteLinkWithStat,
  LocalInviteLink,
  InviteLinkWithKey
} from '@/includes/logic/InviteLinks/types'

import { seriesByMetricInList } from "piramis-base-components/src/shared/utils/seriesByMetricInList";
import { fromToTicks } from "piramis-base-components/src/shared/utils/fromToTicks";

import { cloneDeep } from "lodash";
import VueI18n from "vue-i18n";
import moment from "moment";
import TokenId from "piramis-base-components/src/shared/utils/TokenId";

async function getInviteLinksStat(board_key: string, target_id: number, body: InviteLinkStatReq): Promise<InviteLinksWithStat> {
  return InviteLinksApi.getInviteLinksStat('tg', { board_key, target_id, ...body })
      .then(res =>  res.links)
      .catch((e) => {
        errorNotification(e)

        return []
      })
}

function isLinkActive(stat: InviteLinkWithStat['items']) {
  return (stat ?? []).some(i => i.first_enter > 0)
}

function linkStatistics(linkCode: InviteLinkWithKey['key'], statistics: InviteLinksWithStat) {
  return statistics.find(s => s.data.id === (linkCode && Number.parseInt(TokenId.getId(linkCode))))
}

function prepareInviteLinks(links: Array<InviteLinkWithKey>, statistics: InviteLinksWithStat) {
  const linksCopy = cloneDeep(links)

  return linksCopy.map(data => {
    const items = linkStatistics(data.key, statistics)?.items

    if (Array.isArray(items) && isLinkActive(items)) {
      return {
          items,
          data
      }
    } else {
      return {
        data,
      }
    }
  })
}

async function prepareSimpleSeriesForLinks(links: Array<Omit<LocalInviteLink, 'chart'>>, i18n: VueI18n) {
  const fromTo = fromToTicks(
      moment().add(-7, 'd').format('YYYY-MM-DD 00:00:00'),
      moment().format('YYYY-MM-DD 00:00:00'),
      {
        format: 'YYYY-MM-DD 00:00:00'
      }
  )

  const linksPromises = links.map<Promise<LocalInviteLink>>(async (link) => {
    if (link.items && link.items.length) {
      const series = await seriesByMetricInList(i18n, link.items, [ 'first_enter', 'first_leave' ], fromTo)
          .calcSeries()
          .then((res) => res)

      return {
        ...link,
        chart: {
          series
        }
      }
    } else {
      return {
        ...link,
        chart: {
          series: [
            {
              name: i18n.t('invite_link_enter').toString(),
              data: [ link.data.enter ],
              type: 'bar'
            },
            {
              name: i18n.t('invite_link_kick').toString(),
              data: [ link.data.kick ],
              type: 'bar'
            },
            {
              name: i18n.t('invite_link_leave').toString(),
              data: [ link.data.leave ],
              type: 'bar'
            }
          ]
        }
      }
    }
  })

  return Promise.all(linksPromises)
}

export {
  getInviteLinksStat,
  prepareInviteLinks,
  prepareSimpleSeriesForLinks,
}
