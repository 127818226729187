var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-card',{staticClass:"channel-subscription-notifies",attrs:{"title":_vm.$t('subscription_notify_section_title')}},[_c('a-alert',{staticClass:"mb-2",attrs:{"show-icon":"","message":_vm.$t('subscription_notify_section_alert_message')}}),_c('config-field',{attrs:{"has-anchor":"","title":_vm.$t('notify_time'),"mini-help-message":_vm.$t('notify_time_mini_help_message'),"field-key":"channel_notify_time"}},[_c('div',{staticClass:"flex flex-col xs:flex-row xs:gap-5"},[_c('time-picker-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.limitedSubscriptionConfig,
            'key': 'from',
            'time24hr': true,
            'clearable': true,
          },
        }}}),_c('time-picker-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.limitedSubscriptionConfig,
            'key': 'to',
            'time24hr': true,
            'clearable': true,
          },
        }}})],1)]),_c('a-divider'),_c('config-field',{attrs:{"has-anchor":"","title":_vm.$t('field_subscription_notify_title'),"mini-help-message":_vm.$t('field_subscription_notify_mini_help_message'),"field-key":"channel_subscription_notify"}},[_c('a-button',{attrs:{"slot":"titleButton","type":"primary","icon":"plus","title":_vm.$t('add_notify')},on:{"click":_vm.addRawNotify},slot:"titleButton"}),(_vm.notifies.length === 0)?_c('empty-data',{staticClass:"mt-2"}):_c('div',{staticClass:"mt-2"},_vm._l((_vm.notifies),function(item){return _c('a-card',{key:item.guid,ref:("notify-" + (item.guid)),refInFor:true,staticClass:"notify-card mb-2 last:mb-0"},[_c('div',{staticClass:"flex gap-5 md:flex-row flex-col"},[_c('div',{staticClass:"md:w-1/2 w-full"},[_c('time-unit-input',{staticClass:"mt-0 mb-0 pt-0",attrs:{"setup":{
                'func': _vm.modelSetter,
                'args': {
                  'model': item.value,
                  'key': 'time',
                  'units': [ _vm.StepUnit.Hour, _vm.StepUnit.Day, _vm.StepUnit.Week, _vm.StepUnit.Month ],
                  'prefix': 'subscription_notify_',
                  'disabled': _vm.subscriptionType === 'None',
                  'supportButtons': false
                }
              }}})],1),_c('div',{staticClass:"md:w-1/2 w-full"},[_c('action-card-action',{attrs:{"limit-types":[],"flow-options":_vm.flowOptions,"board-key":_vm.$store.getters.activeBoard.board,"editor-buttons":_vm.actionEditorButtons,"empty-flow-button-callback":function () { return _vm.$router.push({ name: 'Flow' }); }},model:{value:(item.value.action),callback:function ($$v) {_vm.$set(item.value, "action", $$v)},expression:"item.value.action"}})],1)]),_c('a-icon',{attrs:{"slot":"actions","type":"delete"},on:{"click":function($event){return _vm.handleRemoveItem(item.guid)}},slot:"actions"})],1)}),1),(_vm.notifies.length > 2)?_c('add-entity-card',{staticClass:"w-full mt-2",staticStyle:{"min-height":"48px"},attrs:{"title":_vm.$t('add_notify')},on:{"click":_vm.addRawNotify}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }