


































import { ChangeAchievementsRequest } from "./ChangeAchievementsRequest";
import { InputSetups } from "@/mixins/input-setups";

import { SelectOption } from "piramis-base-components/src/logic/types";
import SelectInput from "piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue";

import { Component, Mixins, Prop, VModel, Watch } from 'vue-property-decorator';
import { ChatkeeperConfig } from "@/components/Flow/Nodes/Additionally/IntegrationCustom/types";

@Component({
  components: {
    SelectInput
  }
})
export default class ChangeAchievementsRequestSetting extends Mixins(InputSetups) {
  @VModel() model!: ChangeAchievementsRequest

  @Prop() config!: ChatkeeperConfig | null

  @Watch('config', { immediate: true, deep: true })
  onConfigChange(config: ChatkeeperConfig | null) {
    this.achievementsOptions = (config?.achievements ?? []).map(a => {
      return {
        label: a.title,
        value: a.id
      }
    })

  }

  achievementsOptions: Array<SelectOption> = []

  get achievementsModeOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t('achievements_mode_add'),
        value: 'Add',
      },
      {
        label: this.$t('achievements_mode_remove'),
        value: 'Remove',

      }
    ]
  }
}
