
























































































































































































import { InputSetups } from '@/mixins/input-setups'
import EditorButtonsHelpView from '../../components/EditorButtonsHelpView.vue'
import SaveChannelConfig from '@/components/SaveChannelConfig.vue'
import { InviteLinkApproveType } from '@/includes/logic/InviteLinks/types';
import CaptchaMessageHelper from "@/mixins/CaptchaMessageHelper";

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";
import ActivationActionData
  from "piramis-base-components/src/shared/modules/BotFeedback/ActivationAction/ActivationActionData.vue";
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { MediaEditorMode } from 'piramis-base-components/src/components/NewMessageEditors/types'
import MultiMessageEditorWithMediaInput
  from 'piramis-base-components/src/components/Pi/fields/NewMultiMessageEditorWithMediaInput/MultiMessageEditorWithMediaInput.vue'
import ExtendedHelpMessage from 'piramis-base-components/src/components/ExtendedHelpMessage.vue'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import {
  UserActivationAction,
  UserActivationActionTypeEnum
} from 'piramis-base-components/src/shared/modules/BotFeedback/ActivationAction';

import { mapActions } from 'vuex'
import { Component, Mixins } from 'vue-property-decorator'

@Component({
  data() {
    return {
      EditorButtonsHelpView,
      InviteLinkApproveType,
      EntityTypes,
      MediaEditorMode,
    }
  },
  components: {
    SaveChannelConfig,
    ConfigField,
    ActivationActionData,
    MultiMessageEditorWithMediaInput,
    ExtendedHelpMessage
  },
  methods: {
    ...mapActions([ 'resolveFlowSelectOption' ])
  }
})
export default class ChannelInviteLinksSettings extends Mixins(InputSetups, UseFields, CaptchaMessageHelper) {

  resolveFlowSelectOption!: Promise<Array<SelectOption>>

  get user_captcha_success_action() {
    return this.$store.state.channelsState.activeChannel!.config.user_captcha_success_action
  }

  set user_captcha_success_action(value: UserActivationAction) {
    this.setActivationAction('user_captcha_success_action', value)
  }

  get user_accept_action() {
    return this.$store.state.channelsState.activeChannel!.config.user_accept_action
  }

  set user_accept_action(value: UserActivationAction) {
    this.setActivationAction('user_accept_action', value)
  }

  get user_reject_action() {
    return this.$store.state.channelsState.activeChannel!.config.user_reject_action
  }

  set user_reject_action(value: UserActivationAction) {
    this.setActivationAction('user_reject_action', value)
  }

  get limitTypes() {
    if (this.$store.state.boardsState.activeBoard?.license_expired) {
      return [ UserActivationActionTypeEnum.Flow ]
    }
  }

  get isManualApprove() {
    return this.$store.state.channelsState.activeChannel!.config.approve === InviteLinkApproveType.Manual
  }

  get isAutoApprove() {
    return this.$store.state.channelsState.activeChannel!.config.approve === InviteLinkApproveType.Auto
  }

  get approveTypeOptions(): Array<SelectOptionData> {
    return Object.values(InviteLinkApproveType)
      .filter(type => type !== InviteLinkApproveType.Disabled)
      .map((type) => {
        return {
          label: this.$t(`link_approve_type_${ type.toLowerCase() }`).toString(),
          value: type
        }
      })
  }

  get hasCaptcha() {
    return this.hasCaptchaButton(this.$store.state.channelsState.activeChannel!.config.auto_accepted_user_message)
  }

  get captchaSuccessActionPrefix() {
    const fieldName = 'user_captcha_success_action'

    if (this.isAutoApprove) {
      return `auto_${ fieldName }_`
    } else if (this.isManualApprove) {
      return `manual_${ fieldName }_`
    } else {
      return `${ fieldName }_`
    }
  }

  setActivationAction(key: string, value: UserActivationAction) {
    this.$store.commit('pi/EXEC', {
      'key': key,
      'fn': () => {
        this.$set(this.$store.state.channelsState.activeChannel!.config, key, value)
      },
    })
  }

  gotoFlows(): void {
    this.$router.push({
      name: 'Flows_List',
      params: {
        [ EntityTypes.BOT_ID ]: this.$route.params[ EntityTypes.BOT_ID ]
      }
    })
  }
}
