



















import Vue from 'vue'
import { Component } from 'vue-property-decorator'

import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import BotProperties from 'piramis-base-components/src/components/BotProperties/BotPropertiesAndLabels.vue'
import { BotProperty } from 'piramis-base-components/src/components/BotProperties/components/Properties/types'

import { ExportToCsv } from 'export-to-csv'

@Component({
  components: {
    PageTitle,
    BotProperties,
  }
})
export default class NewBotProperties extends Vue {
  isLoading = false

  exportToCsv(target: string, items: Array<any>): void {
    const options = {
      'fieldSeparator': ';',
      'quoteStrings': '"',
      'decimalSeparator': '.',
      'showLabels': true,
      'showTitle': true,
      'filename': this.$t(`${ target }_csv_table`, { bot: this.$store.state.boardsState.activeBoard!.title }).toString(),
      'title': this.$t(`${ target }_csv_table`, { bot: this.$store.state.boardsState.activeBoard!.title }).toString(),
      'useTextFile': false,
      'useBom': true,
      'useKeysAsHeaders': true,
      'headers': undefined,
    }

    new ExportToCsv(options).generateCsv(items)
  }

  deleteLabel(index: number) {
    return this.$store.dispatch('deleteLabel', {
      index,
      board_key: this.$store.state.boardsState.activeBoard!.board,
    })
  }

  createLabel(label: string): Promise<{ id: number, name: string }> {
    return new Promise(resolve => {
      this.$store.dispatch('createLabel', label)
        .then(({ index }) => {
          resolve({
            id: index,
            name: label
          })
        })
    })
  }

  renameLabel(index: number, title: string): Promise<any> {
    return this.$store.dispatch('renameLabel', {
      board_key: this.$store.state.boardsState.activeBoard!.board,
      index,
      title
    })
  }

  createProperty(path: string, name: string, parent?: number, data?: Record<string, any> | null): Promise<BotProperty> {
    return this.$store.dispatch('addBoardProperty', { path, name, parent, data })
  }

  renameProperty(id: number, name: string): Promise<BotProperty> {
    return this.$store.dispatch('renameBoardProperty', { id, name })
  }

  deleteBoardProperty(id: number): Promise<BotProperty> {
    return this.$store.dispatch('deleteBoardProperty', { id })
  }

  saveConfig(labels: { [key: number]: string }): Promise<void> {
    this.isLoading = true

    this.$store.commit('set_labels_to_store', labels)

    return new Promise(resolve => {
      this.$store.dispatch('savePostBoardConfig')
        .then(() => resolve())
        .finally(() => this.isLoading = false)
    })
  }
}
