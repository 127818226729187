var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chatkeeper-integration-setting"},[_c('select-input',{staticClass:"mt-0",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'requestType',
        'prefix': 'integration_',
        'options': _vm.typeOptions,
        'clearable': false,
      },
    }}}),_c('text-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'token',
        'prefix': 'integration_',
        'validation': 'required',
      }
    }},scopedSlots:_vm._u([(_vm.model.data.type === _vm.ChatkeeperRequestTypeEnum.ChangeAchievements || _vm.model.data.type === _vm.ChatkeeperRequestTypeEnum.RunTrigger)?{key:"field-button",fn:function(){return [_c('a-button',{attrs:{"icon":"undo","loading":_vm.model.configIsLoading},on:{"click":function($event){return _vm.model.getChatkeeperConfig()}}},[_vm._v(" "+_vm._s(_vm.$t('integration_chatkeeper_get_chat_data'))+" ")])]},proxy:true}:null],null,true)}),(_vm.ChatkeeperIntegrationProxy.builders[_vm.model.data.type] && _vm.ChatkeeperIntegrationProxy.builders[_vm.model.data.type].settings)?_c(_vm.ChatkeeperIntegrationProxy.builders[_vm.model.data.type].settings,{tag:"component",attrs:{"config":_vm.chatkeeperConfig},model:{value:(_vm.model.data),callback:function ($$v) {_vm.$set(_vm.model, "data", $$v)},expression:"model.data"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }