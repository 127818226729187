





















































import { InputSetups } from '@/mixins/input-setups'
import { FormConfig } from '@/includes/types/Form.types'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import FilesListRowScroll from 'piramis-base-components/src/components/File/FilesList/FilesListRowScroll.vue'
import UploadMediaModal from 'piramis-base-components/src/components/MediaUploader/UploadMediaModal.vue'
import UploadMediaTriggerArea from 'piramis-base-components/src/components/MediaUploader/UploadMediaTriggerArea.vue'
import { FileType } from 'piramis-base-components/src/components/File/types'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'

import { Component, Mixins, VModel, Watch } from 'vue-property-decorator'
import FileApi from 'piramis-base-components/src/components/File/logic/file-api'

@Component({
  components: {
    UploadMediaModal,
    FilesListRowScroll,
    UploadMediaTriggerArea,
    ConfigField
  }
})
export default class FormSuccessMessageSetting extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel({ default: () => {} }) formSuccess!:FormConfig

  attachments:Array<any> = []

  mediaModelOpen = false

  fileApi = new FileApi()

  @Watch('attachments', { deep: true })
  onAttachmentsChange(files: any) {
    if (this.formSuccess.success_message && this.formSuccess.success_message.attachments) {
      if (files.length) {
        this.formSuccess.success_message.attachments = files.map(f => f?.file ?? f)
      } else {
        this.formSuccess.success_message.attachments = []
      }
    }
  }

  get omittedTypes(): Array<FileType> {
    return [ FileType.MessagePreview, FileType.Audio, FileType.Document, FileType.VideoNote, FileType.Voice, FileType.Animation, FileType.Video ]
  }

  filesDataChange(field: any) {
    this.attachments.push(field)
  }

  removeFile(index: number):void {
    this.attachments.splice(index, 1)
  }

  getImagePreview(descriptor) {
    return `${ this.fileApi.getFilePreviewUrl(descriptor, this.$store.state.boardsState.activeBoard?.board) }`
  }

  mounted():void {
    if (!this.formSuccess?.success_message) {

      this.$set(this.formSuccess, 'success_message', {})
      this.$set(this.formSuccess.success_message!, 'text', '')
      this.$set(this.formSuccess.success_message!, 'attachments', [])

    } else {
      if (this.formSuccess.success_message?.attachments) {
        this.attachments = this.formSuccess.success_message?.attachments.slice().map(d => ({ preview: this.getImagePreview(d), file: d  }))
      } else {
        this.attachments = []
      }
    }
  }
}
