import { ProjectButtonTypes } from '@/components/ProjectButtons/ProjectButtonTypes'
import HiddenMessageButtonBuilder, {
  HiddenMessageButton,
  HiddenMessageButtonData
} from '@/components/ProjectButtons/HiddenMessageButton'

import { plainToInstance } from 'class-transformer'
import { Guid } from 'guid-typescript'

export default class SimpleHiddenMessageButtonBuilder extends HiddenMessageButtonBuilder {
  titleKey = ProjectButtonTypes.SimpleHiddenMessageButton

  type = ProjectButtonTypes.SimpleHiddenMessageButton

  getData(): HiddenMessageButtonData {
    return new HiddenMessageButtonData(plainToInstance(HiddenMessageButton, {
      text: this.$i18n.t('initial_text_message_editor_buttons').toString(),
      subscribedMessage: '',
      notEnoughSubscribedMessage: null,
      unsubscribedMessage: '',
      daysCount: 0
    }))
  }

  create(): HiddenMessageButton {
    return plainToInstance(HiddenMessageButton, {
      ...this.data,
      type: ProjectButtonTypes.HiddenMessageButton,
      guid: Guid.create().toString(),
    })
  }
}