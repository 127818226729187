import { ChatkeeperRequestTypeEnum } from "@/components/Flow/Nodes/Additionally/IntegrationCustom/ChatkeeperIntegration/types";
import { ChatkeeperIntegrationRequest } from "./ChatkeeperIntegrationRequest";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

export abstract class ChatkeeperIntegrationRequestBuilder {
    abstract type: ChatkeeperRequestTypeEnum

    abstract getModel(): ChatkeeperIntegrationRequest

    abstract settings: VueComponent | null
    abstract preview: VueComponent | null
}