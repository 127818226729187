import { ChangeAchievementsRequest } from "./ChangeAchievements/ChangeAchievementsRequest";
import { ChangePointsRequest } from "./ChangePointsRequest/ChangePointsRequest";
import { ChangeReputationRequest } from "./ChangeReputationRequest/ChangeReputationRequest";
import { CompleteCaptchaRequest } from "@/components/Flow/Nodes/Additionally/IntegrationCustom/ChatkeeperIntegration/CompleteCaptcha/CompleteCaptchaRequest";
import { RunTriggerRequest } from "./RunTrigger/RunTriggerRequest";
import { SendMessageRequest } from "./SendMessageRequest/SendMessageRequest";
import { ChatkeeperRequestTypeEnum } from "./types";

export const transformerSubtypes = [
  { name: ChatkeeperRequestTypeEnum.SendMessage, value: SendMessageRequest },
  { name: ChatkeeperRequestTypeEnum.ChangeReputation, value: ChangeReputationRequest },
  { name: ChatkeeperRequestTypeEnum.ChangePoints, value: ChangePointsRequest },

  { name: ChatkeeperRequestTypeEnum.ChangeAchievements, value: ChangeAchievementsRequest },
  { name: ChatkeeperRequestTypeEnum.RunTrigger, value: RunTriggerRequest },
  { name: ChatkeeperRequestTypeEnum.CompleteCaptcha, value: CompleteCaptchaRequest },
]
