



























import { RedirectButton } from '@/components/ProjectButtons/RedirectButton'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component
export default class RedirectButtonSetupView extends Mixins<UseFields, ModelSetter>(UseFields, ModelSetter) {
  @VModel({ 'type': Object }) model!: RedirectButton
}
