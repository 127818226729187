





















































import SlotItem from "@/components/Slots/SlotItem.vue";
import { formatPostSlotTime } from "../../includes/logic/Slots/utils";
import { ChannelSlot, ChannelSlotWithGuid } from "@/includes/types/Board.types";

import { Vue, Component, Prop, Emit, PropSync } from 'vue-property-decorator';
import { omit } from "lodash";

@Component({
  methods: {
    formatPostSlotTime,
    omit,
  },
  components: {
    SlotItem
  }
})
export default class ShowMoreSlots extends Vue {
  @Prop() slotItems!: Array<ChannelSlotWithGuid>

  @PropSync('selectedSlot') slotPublishTo!: ChannelSlotWithGuid | undefined | null

  @Prop({ type: Boolean, required: false, default: false }) disabled!: boolean

  @Prop({ type: Boolean, required: false, default: false }) readonly !: boolean

  @Emit()
  onSlotClick(slot: ChannelSlot) {
    return slot
  }

  selectSlotModalOpen = false

  get channelSlot() {
    if (this.slotPublishTo?.guid) {
      return omit(this.slotPublishTo, [ 'guid' ]) as ChannelSlot
    }

    return this.slotPublishTo
  }

  selectSlot(slot: ChannelSlotWithGuid) {
    if (!this.readonly) {
      this.onSlotClick(slot);

      this.selectSlotModalOpen = false
    }
  }
}
