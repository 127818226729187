import setupView from './FormAnswerButtonSetupView.vue'
import buttonView from './FormAnswerButtonView.vue'
import { ProjectButtonTypes } from '@/components/ProjectButtons/ProjectButtonTypes'

import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import BaseButtonBuilder, { BaseButtonViewData }  from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/index'
import { BaseButton, } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/types'

import { Guid } from 'guid-typescript'
import { plainToInstance } from 'class-transformer'
import i18n from 'vue-i18n'

interface Props {
    getFormsOptions: () => Promise<Array<SelectOptionData>>
}

export default class FormAnswerButtonBuilder extends BaseButtonBuilder {
    type = ProjectButtonTypes.FormAnswerButton

    titleKey = ProjectButtonTypes.FormAnswerButton

    setupView = setupView
    buttonView = buttonView

    icon = '📋'

    data = new FormAnswerButtonData(this.$i18n.t('initial_text_message_editor_buttons').toString())

    declare buttonProps: Props

    constructor(i18n: i18n, getFormsOptions: () => Array<SelectOptionData>, createFormButtonCallback: () => void) {
        super(i18n, { getFormsOptions, createFormButtonCallback })
    }

    getData(button?: FormAnswerButton): FormAnswerButtonData {
        return new FormAnswerButtonData(button ? button : this.$i18n.t('initial_text_message_editor_buttons').toString())
    }

    create(): FormAnswerButton {
        return plainToInstance(FormAnswerButton, {
            ...this.data,
            type: ProjectButtonTypes.FormAnswerButton,
            guid: Guid.create().toString()
        })
    }
}

export class FormAnswerButtonData implements BaseButtonViewData {
    key: string | null = null
    text: string = ''

    constructor(data: FormAnswerButton | string) {
        if(data instanceof FormAnswerButton || typeof data === 'object') {
            const { key, text } = data

            this.key = key
            this.text = text
        } else {
            this.text = data
        }
    }
}

export class FormAnswerButton implements BaseButton {
    text!: string
    type!: ProjectButtonTypes.FormAnswerButton
    guid!: string
    key!: string
}