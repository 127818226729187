import { ChatkeeperRequestTypeEnum } from "@/components/Flow/Nodes/Additionally/IntegrationCustom/ChatkeeperIntegration/types";
import { RunTriggerRequest } from "./RunTriggerRequest";
import { ChatkeeperIntegrationRequestBuilder } from "../IntegrationRequest/ChatkeeperIntegrationRequestBuilder";

import { plainToInstance } from "class-transformer";

export const RunTriggerRequestBuilder = new class RunTriggerRequestBuilder extends ChatkeeperIntegrationRequestBuilder {
    type: ChatkeeperRequestTypeEnum = ChatkeeperRequestTypeEnum.RunTrigger;

    settings = () => import('./RunTriggerRequestSetting.vue')
    preview = () => import('./RunTriggerRequestPreview.vue')

    getModel() {
        return plainToInstance(RunTriggerRequest, {
            type: ChatkeeperRequestTypeEnum.RunTrigger,
            token: '',
            trigger_id: ''
        })
    }
}
