












import { ChannelSlot, ChannelSlotWithGuid } from "@/includes/types/Board.types";
import { formatPostSlotTime } from '@/includes/logic/Slots/utils';

import { Vue, Component, Prop, Emit } from 'vue-property-decorator';

@Component({
  methods: {
    formatPostSlotTime
  }
})
export default class SlotItem extends Vue {
  @Prop() slotItem!: ChannelSlotWithGuid

  @Prop({ type: Boolean, required: false, default: false }) disabled!: boolean

  @Prop({ type: Boolean, required: false, default: false }) readonly !: boolean

  @Emit()
  onSlotClick(slot: ChannelSlot) {
    return slot
  }
}
