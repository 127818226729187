import { ChatkeeperRequestTypeEnum } from "@/components/Flow/Nodes/Additionally/IntegrationCustom/ChatkeeperIntegration/types";
import { CompleteCaptchaRequest } from "./CompleteCaptchaRequest";
import { ChatkeeperIntegrationRequestBuilder } from "../IntegrationRequest/ChatkeeperIntegrationRequestBuilder";

import { plainToInstance } from "class-transformer";

export const CompleteCaptchaRequestBuilder = new class CompleteCaptchaRequestBuilder extends ChatkeeperIntegrationRequestBuilder {
    type: ChatkeeperRequestTypeEnum = ChatkeeperRequestTypeEnum.CompleteCaptcha;

    settings = null;
    preview = null;

    getModel() {
        return plainToInstance(CompleteCaptchaRequest, {
            type: ChatkeeperRequestTypeEnum.CompleteCaptcha,
        })
    }
}
