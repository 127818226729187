import PostApi from 'piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi'

import { AxiosRequestConfig } from 'axios'

export default class BaseApi {
    static async sendRequest<T = any, D = any>(type: string, method: string, body: any, config: AxiosRequestConfig = {}) {
            return PostApi.sendRequest<T, D>(type, method, body, config)
                .then(res => res.data)
                .catch((error) => {
                    throw error
                })
    }
}
