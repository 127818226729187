






import { RunTriggerRequest } from "./RunTriggerRequest";

import { Component, Prop } from 'vue-property-decorator';
import Vue from "vue";
import VueI18n from "vue-i18n";
import { ChatkeeperIntegrationProxy } from "@/components/Flow/Nodes/Additionally/IntegrationCustom/ChatkeeperIntegration";

@Component({
})
export default class RunTriggerRequestPreview extends Vue {
  @Prop() model!: RunTriggerRequest

  @Prop() i18n!: VueI18n

  get triggerLabel() {
    if (ChatkeeperIntegrationProxy.chatkeeperConfig.data) {
      return ChatkeeperIntegrationProxy.chatkeeperConfig.data.triggers.find(t => t.id === this.model.trigger_id)?.name
    } else {
      return 'Trigger not found'
    }
  }
}
