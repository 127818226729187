import BaseApi from '@/includes/services/BaseApi'
import { BaseBoardRequest } from "@/includes/types/Api.types";

export class FlowStatisticsService {

    static getFlowConnectionsStatistics(type: string, body: BaseBoardRequest<{ flow_key: string, begin: string, end: string }>) {
        return BaseApi.sendRequest<{data: Array<any>}>(type, 'getflowconnectionsstatistics', body)
    }

    static getFlowConnectionStatistics(type: string, body: BaseBoardRequest<{ output: number, input: number, flow_key: string, begin: string, end: string }>) {
        return BaseApi.sendRequest<{data: Array<any>}>(type, 'getflowconnectionstatistics', body)
    }

    static getFlowUsersStatistics(type: string, body: BaseBoardRequest<{ user: number, flow_key: string, begin: string, end: string }>) {
        return BaseApi.sendRequest<{data: Array<any>}>(type, 'getflowusersstatistics', body)
    }

    // static getArticle(type: string, body: BaseBoardRequest<{key: string }): Promise<any> {
    //     return BaseApi.sendRequest(type, 'GetArticle', body)
    // }
}