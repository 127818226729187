














import i18n from '@/i18n/i18n'
import { BoardsApi } from '@/includes/Api/Boards.api'
import { TargetState } from '@/includes/types/Board.types'
import { ProjectButtonTypes } from '@/components/ProjectButtons/ProjectButtonTypes'
import { RunFlowButton } from '@/components/ProjectButtons/RunFlowButton'
import { FormAnswerButton } from '@/components/ProjectButtons/FormAnswerButton'
import { ShareTextButton } from '@/components/ProjectButtons/ShareTextButton'
import { FormButton } from '@/components/ProjectButtons/FormButton'
import { RedirectButton } from '@/components/ProjectButtons/RedirectButton'
import { PopupButton } from '@/components/ProjectButtons/PopupButton'
import { HiddenMessageButton } from '@/components/ProjectButtons/HiddenMessageButton'
import { HiddenCountedLinkButton } from '@/components/ProjectButtons/HiddenCountedLinkButton'
import { HiddenCountedMessageButton } from '@/components/ProjectButtons/HiddenCountedMessageButton'
import { LimitedSubscriptionLinkButton } from '@/components/ProjectButtons/LimitedSubscriptionLinkButton'
import { HiddenSubscriberLinkButton } from '@/components/ProjectButtons/HiddenSubscriberLinkButton'
import { CommentsButton } from '@/components/ProjectButtons/CommentsButton'
import { IntegrationNodeDataCustom } from './components/Flow/Nodes/Additionally/IntegrationCustom/IntegrationCustom'

import { hasMatchedRouteParams } from 'piramis-base-components/src/components/SelectEntityWizard/includes/helpers'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import SelectEntityWizard from 'piramis-base-components/src/components/SelectEntityWizard/SelectEntityWizard.vue'
import MessageEditorModelButtonsFactory
  from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/logic/MessageEditorModelButtonsFactory'
import {
  ButtonTypes
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/types'
import { initBotProperties } from 'piramis-base-components/src/components/BotProperties/utils'
import { NodeTypes } from "piramis-base-components/src/components/Flow/new/logic/Nodes";
import { FlowNodesModelsFactory } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/BaseNode/BaseNodeModel'

import { Watch, Vue } from 'vue-property-decorator'
import axios from 'axios'
import Component from 'vue-class-component'
import ruRu from 'ant-design-vue/lib/locale-provider/ru_RU'
import enUS from 'ant-design-vue/lib/locale-provider/en_US'
import moment from 'moment'
import TokenId from "piramis-base-components/src/shared/utils/TokenId";
import { PropertyFieldType } from "piramis-base-components/src/components/BotProperties/components/Properties/types";

@Component({
  components: {
    SelectEntityWizard
  }
})
export default class App extends Vue {

  @Watch('$store.state.AdminTemplate.runtime_config')
  onChange() {
    initBotProperties(
      this.$store.state.AdminTemplate?.runtime_config?.available_properties ?? [], {
        'InviteLink': {
          icon: 'link',
        },
        'Files': {
          icon: 'attach_file',
        },
        'ChatKeeperPoints': {
          icon: 'exposure_plus_2',
          settingView: () => import('./components/Properties/ChatkeeperPropertySetting.vue')
        },
        'ChatKeeperReputation': {
          icon: 'thumb_up',
          settingView: () => import('./components/Properties/ChatkeeperPropertySetting.vue')
        },
        'ChatKeeperChecking': {
          icon: 'door_back',
          settingView: () => import('./components/Properties/ChatkeeperPropertySetting.vue')
        },
      })
  }
  
  get currentLocale() {
    moment.locale(this.$i18n.locale)

    if (this.$i18n.locale === 'ru') {
      return ruRu
    }

    if (this.$i18n.locale === 'en') {
      return enUS
    }

    return ruRu
  }

  map = {
    [EntityTypes.BOT_ID]: () => {
      return this.$store.dispatch('requestBoards')
        .then(() => {
          return this.$store.getters.sortedBoards
            .map(b => ({ id: TokenId.getId(b.board), title: b.title, avatar: b.avatar }))
        })
    },
    [EntityTypes.CHANNEL_ID]: (botId:number) => {
      if (!this.$store.getters.isBoardSet) {
        return BoardsApi.getBoardChannels('tg', { board_key: this.$store.getters.getBoardById(botId).board })
          .then((data) => {
            return data.channels
              .filter(c => c.state === TargetState.Board)
              .sort((a, b) => b.members_count - a.members_count)
              .map(c => ({ id: c.id, title: c.title, avatar: c.avatar }))
          })
      }

      return () => []
    },
  }

  get containsSelectConstants():boolean {
    return hasMatchedRouteParams(this.$route)
  }

  registerPresscodeButtons() {
    MessageEditorModelButtonsFactory.SetModelFactory(ButtonTypes.RedirectButton, RedirectButton)
    MessageEditorModelButtonsFactory.SetModelFactory(ProjectButtonTypes.RunFlowButton, RunFlowButton)
    MessageEditorModelButtonsFactory.SetModelFactory(ProjectButtonTypes.FormAnswerButton, FormAnswerButton)
    MessageEditorModelButtonsFactory.SetModelFactory(ProjectButtonTypes.ShareTextButton, ShareTextButton)
    MessageEditorModelButtonsFactory.SetModelFactory(ProjectButtonTypes.FormButton, FormButton)
    MessageEditorModelButtonsFactory.SetModelFactory(ProjectButtonTypes.PopupButton, PopupButton)
    MessageEditorModelButtonsFactory.SetModelFactory(ProjectButtonTypes.HiddenMessageButton, HiddenMessageButton)
    MessageEditorModelButtonsFactory.SetModelFactory(ProjectButtonTypes.HiddenCountedLinkButton, HiddenCountedLinkButton)
    MessageEditorModelButtonsFactory.SetModelFactory(ProjectButtonTypes.HiddenCountedMessageButton, HiddenCountedMessageButton)
    MessageEditorModelButtonsFactory.SetModelFactory(ProjectButtonTypes.LimitedSubscriptionLinkButton, LimitedSubscriptionLinkButton)
    MessageEditorModelButtonsFactory.SetModelFactory(ProjectButtonTypes.HiddenSubscriberLinkButton, HiddenSubscriberLinkButton)
    MessageEditorModelButtonsFactory.SetModelFactory(ProjectButtonTypes.CommentsButton, CommentsButton)
  }

  registerPresscodeNodes() {
    FlowNodesModelsFactory.SetModelFactory(NodeTypes.Integration, IntegrationNodeDataCustom)
  }

  mounted() {
    axios.get(`/translations/common.json`).then(({ data }) => {

      i18n.mergeLocaleMessage('ru', data['ru'])
      i18n.mergeLocaleMessage('en', data['en'])

      this.$i18n.mergeLocaleMessage('ru', data['ru'])
      this.$i18n.mergeLocaleMessage('en', data['en'])
    }).catch(console.error)

    this.registerPresscodeButtons()
    this.registerPresscodeNodes()
  }
}
