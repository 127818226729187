import buttonView from './HiddenCountedMessageButton.vue'
import setupView from './HiddenCountedMessageButtonSetupView.vue'
import { ProjectButtonTypes } from '@/components/ProjectButtons/ProjectButtonTypes'

import BaseButtonBuilder, {
  BaseButtonViewData
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons'
import {
  BaseButton
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/types'

import VueI18n from 'vue-i18n'
import { plainToInstance } from 'class-transformer'
import { Guid } from 'guid-typescript'

export default class HiddenCountedMessageButtonBuilder extends BaseButtonBuilder {
  type = ProjectButtonTypes.HiddenCountedMessageButton

  titleKey = ProjectButtonTypes.HiddenCountedMessageButton

  setupView = setupView
  buttonView = buttonView

  icon = '✉️👆'

  data = new HiddenCountedMessageButtonData(this.$i18n.t('initial_text_message_editor_buttons').toString())

  constructor(i18n: VueI18n) {
    super(i18n)
  }

  getData(button?: HiddenCountedMessageButton): HiddenCountedMessageButtonData {
    return new HiddenCountedMessageButtonData(button ? button : this.$i18n.t('initial_text_message_editor_buttons').toString())
  }

  create(): HiddenCountedMessageButton {
    return plainToInstance(HiddenCountedMessageButton, {
      ...this.data,
      type: ProjectButtonTypes.HiddenCountedMessageButton,
      guid: Guid.create().toString(),
    })
  }
}

export class HiddenCountedMessageButtonData implements BaseButtonViewData {
  emptyText = ''
  grouped = false
  onlyUnique = false
  stickyResponce = true
  limit = 0
  popup = false
  targetValue = ''
  afterTargetValue = ''
  text = ''

  constructor(data: HiddenCountedMessageButton | string) {
      if (data instanceof HiddenCountedMessageButton || typeof data === 'object') {
        const {
          emptyText,
          grouped,
          onlyUnique,
          stickyResponce,
          limit,
          popup,
          targetValue,
          afterTargetValue,
          text
        } = data

        this.emptyText = emptyText
        this.grouped = grouped
        this.onlyUnique = onlyUnique
        this.stickyResponce = stickyResponce
        this.limit = limit
        this.popup = popup
        this.targetValue = targetValue
        this.afterTargetValue = afterTargetValue
        this.text = text
      } else {
        this.text = data
      }
  }
}

export class HiddenCountedMessageButton implements BaseButton {
  text!: string
  type!: ProjectButtonTypes.HiddenCountedMessageButton
  guid!: string
  emptyText!: string
  grouped!: boolean
  onlyUnique!: boolean
  stickyResponce!: boolean
  limit!: number
  popup!: boolean
  targetValue!: string
  afterTargetValue!: string
}
