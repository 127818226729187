



















































import { PopupButton } from '@/components/ProjectButtons/PopupButton'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'

import { Component, Mixins, VModel } from 'vue-property-decorator'

@Component
export default class PopupButtonSetupView extends Mixins<UseFields, ModelSetter>(UseFields, ModelSetter) {
  @VModel({ 'type': Object }) model!: PopupButton
}
