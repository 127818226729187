import BaseApi from "@/includes/services/BaseApi";
import { AddBoardPropertyReq, DeleteBoardPropertyReq, RenameBoardPropertyReq } from '@/includes/types/Properties.types'

import { BotProperty } from 'piramis-base-components/src/components/BotProperties/components/Properties/types'

type ResponseBotProperties = { items: Array<BotProperty> }

export class PropertiesService {
	static async addBoardProperty(type: string, body: AddBoardPropertyReq) {
		return BaseApi.sendRequest<ResponseBotProperties>(type, 'addboardproperty', body)
	}

	static async renameBoardProperty(type: string, body: RenameBoardPropertyReq) {
		return BaseApi.sendRequest<ResponseBotProperties>(type, 'renameboardproperty', body)
	}

	static async deleteBoardProperty(type: string, body: DeleteBoardPropertyReq) {
		return BaseApi.sendRequest<ResponseBotProperties>(type, 'deleteboardproperty', body)
	}
}
