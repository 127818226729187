import { render, staticRenderFns } from "./ChangeAchievementsRequestPreview.vue?vue&type=template&id=dd273e38&scoped=true&"
import script from "./ChangeAchievementsRequestPreview.vue?vue&type=script&lang=ts&"
export * from "./ChangeAchievementsRequestPreview.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "dd273e38",
  null
  
)

export default component.exports