import { ChatkeeperRequestTypeEnum } from "@/components/Flow/Nodes/Additionally/IntegrationCustom/ChatkeeperIntegration/types";
import { ChatkeeperIntegrationRequestBuilder } from "../IntegrationRequest/ChatkeeperIntegrationRequestBuilder";
import { SendMessageRequest } from "./SendMessageRequest";

import { plainToInstance } from "class-transformer";

export const SendMessageRequestBuilder = new class SendMessageRequestBuilder extends ChatkeeperIntegrationRequestBuilder {
    type: ChatkeeperRequestTypeEnum = ChatkeeperRequestTypeEnum.SendMessage;

    settings = () => import('./SendMessageRequestSetting.vue')
    preview = () => import('./SendMessageRequestPreview.vue')

    getModel() {
        return plainToInstance(SendMessageRequest, {
            type: ChatkeeperRequestTypeEnum.SendMessage,
            token: '',
            message: [ {
                attachments: [],
                text: '',
                buttons: [],
                remove_previous: false,
                pin: false,
                disable_link_preview: false,
                disable_notify: false,
                topics: [],
                protect_content: false,
                send_after: 0,
                remove_after: 0
            } ]
        })
    }
}
