


















import { Component, Mixins, VModel } from 'vue-property-decorator';
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import TextInput from 'piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue';

@Component({
  components: {
    TextInput
  }
})
export default class ChatkeeperPropertySetting extends Mixins(ModelSetter) {
  @VModel() model!: null | { token: string }

  created() {
    if (!this.model?.token) {
      this.model = {
        token: ''
      }
    }
  }
}
