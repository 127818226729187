

















import { ChangePointsRequest } from "../ChangePointsRequest/ChangePointsRequest";
import { ChangeReputationRequest } from "../ChangeReputationRequest/ChangeReputationRequest";

import { Component, Prop } from 'vue-property-decorator';
import Vue from "vue";
import VueI18n from "vue-i18n";
import { snakeCase } from "lodash";

@Component({})
export default class ChangeActivityPreview extends Vue {
  @Prop() model!: ChangeReputationRequest | ChangePointsRequest

  @Prop() i18n!: VueI18n

  get values() {
    return [
      {
        title: this.i18n.t(`field_integration_${ snakeCase(this.model.type).toLowerCase() }_value_title`).toString(),
        value: this.model.value.toString()
      },
      ...typeof this.model.lower_limit === 'number' ? [ {
        title: this.i18n.t('field_integration_lower_limit_title').toString(),
        value: this.model.lower_limit.toString()
      } ] : [],
      ...typeof this.model.upper_limit === 'number' ? [ {
        title: this.i18n.t('field_integration_upper_limit_title').toString(),
        value: this.model.upper_limit.toString()
      } ] : [],
      ...this.model.reason.trim().length ? [ {
        title: this.i18n.t('field_integration_reason_title').toString(),
        value: this.model.reason

      } ] : []
    ]
  }
}
