

















































import { ChatkeeperIntegrationProxy, ChatkeeperRequestTypeEnum } from ".";

import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue';
import TextInput from 'piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue';
import { SelectOption } from "piramis-base-components/src/logic/types";
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";

import { Component, Mixins, VModel } from 'vue-property-decorator';
import Vue from "vue";

@Component({
  data() {
    return {
      ChatkeeperIntegrationProxy,
      ChatkeeperRequestTypeEnum
    }
  },
  components: {
    TextInput,
    SelectInput
  }
})
export default class ChatkeeperIntegrationSetting extends Mixins(ModelSetter) {
  @VModel() model!: ChatkeeperIntegrationProxy

  get chatkeeperConfig() {
    return ChatkeeperIntegrationProxy.chatkeeperConfig.data
  }

  get typeOptions(): Array<SelectOption> {
    return [
      {
        label: this.$t(this.model.integrationTitleKey(ChatkeeperRequestTypeEnum.SendMessage)).toString(),
        value: ChatkeeperRequestTypeEnum.SendMessage
      },
      {
        label: this.$t(this.model.integrationTitleKey(ChatkeeperRequestTypeEnum.ChangePoints)).toString(),
        value: ChatkeeperRequestTypeEnum.ChangePoints
      },
      {
        label: this.$t(this.model.integrationTitleKey(ChatkeeperRequestTypeEnum.ChangeReputation)).toString(),
        value: ChatkeeperRequestTypeEnum.ChangeReputation
      },
      {
        label: this.$t(this.model.integrationTitleKey(ChatkeeperRequestTypeEnum.ChangeAchievements)).toString(),
        value: ChatkeeperRequestTypeEnum.ChangeAchievements
      },
      {
        label: this.$t(this.model.integrationTitleKey(ChatkeeperRequestTypeEnum.RunTrigger)).toString(),
        value: ChatkeeperRequestTypeEnum.RunTrigger
      },
      {
        label: this.$t(this.model.integrationTitleKey(ChatkeeperRequestTypeEnum.CompleteCaptcha)).toString(),
        value: ChatkeeperRequestTypeEnum.CompleteCaptcha
      },
    ]
  }

}
