import { render, staticRenderFns } from "./PeriodSettings.vue?vue&type=template&id=54df6ca7&scoped=true&"
import script from "./PeriodSettings.vue?vue&type=script&lang=ts&"
export * from "./PeriodSettings.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54df6ca7",
  null
  
)

export default component.exports