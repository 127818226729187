import { render, staticRenderFns } from "./ChatkeeperIntegrationSetting.vue?vue&type=template&id=12706a47&scoped=true&"
import script from "./ChatkeeperIntegrationSetting.vue?vue&type=script&lang=ts&"
export * from "./ChatkeeperIntegrationSetting.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12706a47",
  null
  
)

export default component.exports