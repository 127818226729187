import { RootState } from '@/store/state'

export default {
    isChannelSet: (state: RootState) => state.channelsState.activeChannel !== null,
    isChannelPrivate: (state: RootState) => {
        return state.channelsState.activeChannel && state.channelsState.activeChannel?.username === undefined
    },
    hasChannelGroup: (state: RootState) => {
        const activeChannel = state.channelsState.activeChannel

        return activeChannel && activeChannel.linked_chat_id !== null
    }
}