











































































import { InputSetups } from '@/mixins/input-setups'
import { CreatedPost, RequestPost } from '@/includes/types/Post.types'
import { PostService } from '@/includes/services/PostService'

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { FieldData } from 'piramis-base-components/src/components/Pi/types'
import { StepUnitAlias, TimeUnitInputMode } from 'piramis-base-components/src/components/PeriodSimplifier/types'
import { PostType } from 'piramis-base-components/src/shared/modules/posting/types'
import PostPeriodConditions from 'piramis-base-components/src/shared/modules/posting/PostPeriod/PostPeriodConditions.vue'

import Component from 'vue-class-component'
import { Mixins, Prop, PropSync, VModel, Watch } from 'vue-property-decorator'
import { debounce } from 'lodash'
import moment from 'moment'

@Component({
  components: {
    PostPeriodConditions
  },
  data() {
    return {
      TimeUnitInputMode,
      PostType,
    }
  }
})
export default class PeriodSettings extends Mixins<InputSetups, UseFields>(InputSetups, UseFields) {
  @VModel() model !: RequestPost

  @PropSync('disabled') isPeriodDisabled!: boolean

  @Prop({ type: Boolean, default: false }) disabledFields!: boolean

  @Watch('model', { deep:true })
  onModelChange(newModel:CreatedPost) {
    if (newModel.post.message.type === PostType.Post) {
      newModel.post.message.variants.forEach(editor => {
        editor.remove_previous = this.removePrevMessage
      })
    }
  }

  @Watch('model.period', { deep: true, immediate: true })
  onConditionsChange = debounce(this.getScheduleDates, 1000)

  @Watch('model.time')
  onRuntimeChange = debounce(this.onTimeChange, 1000)

  scheduleDates: Array<string> | null = null

  datesLoading = false

  private removePrevious = false

  get removePrevMessage():boolean {
    return this.removePrevious
  }

  set removePrevMessage(switchState:boolean) {
    this.removePrevious = switchState

    if (this.model.post.message.type === PostType.Post) {
      this.model.post.message.variants.forEach(editor => {
        editor.remove_previous = switchState
      })

    }
  }

  get moreThen48Hours() {
    if (this.model && this.model.period && this.model.period.interval) {
      if (this.model.period?.unit === StepUnitAlias.HOURS) {
        return this.model.period.interval >= 48
      } else if (this.model.period?.unit === StepUnitAlias.DAYS) {
        return this.model.period.interval > 2
      } else if (this.model.period?.unit === StepUnitAlias.MINUTES) {
        return this.model.period.interval >= 2880
      } else if (this.model.period?.unit === StepUnitAlias.SECONDS) {
        return this.model.period.interval >= 172800
      } else {
        return true
      }
    }

    return false
  }

  get removePrevPostDisabled() {
    return this.disabledFields || this.moreThen48Hours
  }

  get scheduleDate(): string {
    return this.$t('repeat_post_dates_list', [ moment(this.model.time).locale('ru-RU').format('LLL') ]).toString()
  }

  onTimeChange() {
    if (this.model.period && this.model.period.interval && this.model.period.interval > 0) {
      this.getScheduleDates()
    }
  }

  onUnitChange(unit: StepUnitAlias) {
    if (this.model.period) {
      this.model.period.unit = unit
    }
  }

  modelSetter(args: FieldData): FieldData {
    if (this.model && args.key === 'interval' && args.model === null) {
      this.model.period = {
        interval: 0,
        unit: StepUnitAlias.MINUTES,
        conditions: []
      }
    }

    args.setter = (value: any): void => {
      this.$set(args.model, args.key as string, value)
    }
    return args
  }

  async getScheduleDates(): Promise<void> {
    try {
      this.datesLoading = true
      const response = await PostService.getScheduleDates('tg', {
        board_key: this.$store.state.boardsState.activeBoard!.board,
        schedule: {
          timezone: this.model.timezone!,
          run_time: this.model.time!,
          period: this.model.period || null
        }
      })
      if (response.run_time.length < 1) {
        this.scheduleDates = []
        this.isPeriodDisabled = true
      } else {
        this.scheduleDates = response.run_time.map((date) => moment(date).format('LLL'))
        this.isPeriodDisabled = false
      }
    } finally {
      this.datesLoading = false
    }
  }

  mounted() {
    if (this.model.post.message.type === PostType.Post) {
      this.removePrevMessage = this.model.post.message.variants.some(v => v.remove_previous)
    }
  }
}
