import buttonView from './HiddenSubscriberLinkButton.vue'
import setupView from './HiddenSubscriberLinkButtonSetupView.vue'
import { ProjectButtonTypes } from '@/components/ProjectButtons/ProjectButtonTypes'
import { HiddenTargetType } from '@/components/ProjectButtons/includes/helpers/HiddenTargetTypeOptions'
import i18n from '@/i18n/i18n'

import BaseButtonBuilder, {
  BaseButtonViewData
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons'
import {
  BaseButton
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/types'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

import VueI18n from 'vue-i18n'
import { plainToInstance } from 'class-transformer'
import { Guid } from 'guid-typescript'

interface Props {
  getArticlesFn: () => Promise<Array<SelectOptionData>>
}

export default class HiddenSubscriberLinkButtonBuilder extends BaseButtonBuilder {
  type = ProjectButtonTypes.HiddenSubscriberLinkButton

  titleKey = ProjectButtonTypes.HiddenSubscriberLinkButton

  setupView = setupView
  buttonView = buttonView

  icon = '🔔'

  declare buttonProps: Props

  data = new HiddenSubscriberLinkButtonData(this.$i18n.t('initial_text_message_editor_buttons').toString())

  constructor(i18n: VueI18n, getArticlesFn: () => Promise<Array<SelectOptionData>>) {
    super(i18n, { getArticlesFn })
  }

  getData(button?: HiddenSubscriberLinkButton): HiddenSubscriberLinkButtonData {
    return new HiddenSubscriberLinkButtonData(button ? button : this.$i18n.t('initial_text_message_editor_buttons').toString())
  }

  create(): HiddenSubscriberLinkButton {
    return plainToInstance(HiddenSubscriberLinkButton, {
      ...this.data,
      type: ProjectButtonTypes.HiddenSubscriberLinkButton,
      guid: Guid.create().toString(),
    })
  }
}

export class HiddenSubscriberLinkButtonData implements BaseButtonViewData {
  subscribedTargetType = HiddenTargetType.Message
  subscribedTargetValue = i18n.t('initial_text_message_editor_hidden_subscriber_subscribed').toString()
  unsubscribedTargetType = HiddenTargetType.Message
  unsubscribedTargetValue = i18n.t('initial_text_message_editor_hidden_subscriber_unsubscribed').toString()
  notEnoughSubscribedTargetType = HiddenTargetType.Message
  notEnoughSubscribedTargetValue = i18n.t('initial_text_message_editor_hidden_subscriber_not_enough_subscribed').toString()
  daysCount = 31
  text = ''

  constructor(data: HiddenSubscriberLinkButton | string) {
      if (data instanceof HiddenSubscriberLinkButton || typeof data === 'object') {
        const {
          subscribedTargetType,
          subscribedTargetValue,
          unsubscribedTargetType,
          unsubscribedTargetValue,
          notEnoughSubscribedTargetType,
          notEnoughSubscribedTargetValue,
          daysCount,
          text,
        } = data

        this.subscribedTargetType = subscribedTargetType
        this.subscribedTargetValue = subscribedTargetValue
        this.unsubscribedTargetType = unsubscribedTargetType
        this.unsubscribedTargetValue = unsubscribedTargetValue
        this.notEnoughSubscribedTargetType = notEnoughSubscribedTargetType
        this.notEnoughSubscribedTargetValue = notEnoughSubscribedTargetValue
        this.daysCount = daysCount
        this.text = text
      } else {
        this.text = data
      }
  }
}

export class HiddenSubscriberLinkButton implements BaseButton {
  text!: string
  type!: ProjectButtonTypes.HiddenSubscriberLinkButton
  guid!: string
  subscribedTargetType!: HiddenTargetType
  subscribedTargetValue!: string
  unsubscribedTargetType!: HiddenTargetType
  unsubscribedTargetValue!: string
  notEnoughSubscribedTargetType!: HiddenTargetType
  notEnoughSubscribedTargetValue!: string
  daysCount!: number
}
