















































































































































































































































import {
  Board,
  ChannelInfo,
  SlimBoardInfo,
  SlimChannelInfo,
  TargetState,
  TestChannelStateCode
} from '@/includes/types/Board.types'
import ChannelCard from '@/components/ChannelCard.vue'
import { BoardsApi } from '@/includes/Api/Boards.api'
import EmptyBoardsInfo from '@/components/EmptyBoardsInfo.vue'
import { errorNotification } from '@/includes/services/NotificationService'
import PostTypePicker from '@/components/Post/PostTypePicker.vue'
import { canCreatePost, createPostButtonTitle, hasChangeConfigPermission, } from '@/includes/PermissionHelper'
import { CalendarPostsSource } from '@/includes/types/CalendarPostsSource'
import SetBotTokenToBoard from '../components/SetBotTokenToBoard/SetBotTokenToBoard.vue'
import { ADD_BOARD_INSTRUCTION_HASH } from '@/includes/CONSTANTS'
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'
import FreeLicenseActivationAlert from '@/components/FreeLicenseActivationAlert.vue'
import TestStateMixin from '@/mixins/TestStateMixin'
import TestStateInfoModal from '@/components/TestStateInfoModal.vue'

import { seriesByMetricInList } from 'piramis-base-components/src/shared/utils/seriesByMetricInList'
import { fromToTicks } from 'piramis-base-components/src/shared/utils/fromToTicks'
import { ApexChartSeries } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import { atSignedLogin, tgLoginLink } from 'piramis-base-components/src/shared/utils/tgLoginHelpers'
import { PostType } from 'piramis-base-components/src/shared/modules/posting/types'
import TokenId from 'piramis-base-components/src/shared/utils/TokenId'
import AddEntityCard from "piramis-base-components/src/components/AddEntityCard.vue";

import { memoize } from 'lodash'
import { Mixins } from 'vue-property-decorator'
import Component from 'vue-class-component'
import moment from 'moment'
import { Location } from 'vue-router'

@Component({
  data() {
    return {
      hasChangeConfigPermission,
      createPostButtonTitle,
      atSignedLogin,
      tgLoginLink,
    }
  },
  components: {
    FreeLicenseActivationAlert,
    AddEntityCard,
    EmptyBoardsInfo,
    ChannelCard,
    PageTitle,
    EmptyData,
    PostTypePicker,
    SetBotTokenToBoard,
    TestStateInfoModal
  }
})
export default class Boards extends Mixins<TestStateMixin>(TestStateMixin) {
  boardLoading = false

  isPostTypeModalOpen = false

  activeBoard: Pick<Board, 'board' | 'permissions' | 'title'> | null = null

  botAddModalOpen = this.$route.hash === ADD_BOARD_INSTRUCTION_HASH

  targetsStatistics: Record<SlimChannelInfo['id'], ApexChartSeries> = {}

  getBoardId(board: SlimBoardInfo['board']) {
    return TokenId.getId(board)
  }

  handleGotoPlannerPage(board: SlimBoardInfo): void {
    let query: Location['query'] = {
      calendar: CalendarPostsSource.Schedule
    }

    if (!canCreatePost(board.permissions)) {
      query.calendar = CalendarPostsSource.Suggested
    }

    this.boardRoute('posts_planner', this.getBoardId(board.board), query)
  }

  handleModalClose(): void {
    this.activeBoard = null

    this.$store.commit('AdminTemplate/SET_ACCOUNT_DATA_ROOT', null)
  }

  handleCreateNewPostButtonClick(selectedBoard: SlimBoardInfo): void {
    const { board, permissions, title } = selectedBoard

    this.activeBoard = {
      board,
      permissions,
      title
    }

    this.isPostTypeModalOpen = true

    this.$store.commit('AdminTemplate/SET_ACCOUNT_DATA_ROOT', board)
  }

  gotoRoute(name: string, params?: Location['params'], query?: Location['query']) {
    this.$router.push({ name, params, query })
  }

  handleTemplateButtonClick(boardId:string) {
    this.gotoRoute('Style',
                   {
                     [EntityTypes.BOT_ID]: this.getBoardId(boardId),
                     actionType: BaseItemViewAction.New
                   })
  }

  postTypeSelectHandler(data: { type: PostType, board: string, query?: Location['query'] }) {
    this.$store.commit('AdminTemplate/SET_ACCOUNT_DATA_ROOT', null)

    this.gotoRoute('post',
                   {
                     [EntityTypes.BOT_ID]: this.getBoardId(data.board),
                     actionType: 'new',
                   },
                   {
                     type: data.type.toLowerCase(),
                     ...data.query
                   })
  }

  updateChannelState(channelInfo: { state: TestChannelStateCode, channelId: number, boardId: string }): void {
    const { state, channelId, boardId } = channelInfo

    this.$store.dispatch('updateBoardChannel', { board: boardId, channelId, state })
  }

  boardRoute(routeName: string, board: string, query?: Location['query']): void {
    this.gotoRoute(routeName,
                   {
                     [EntityTypes.BOT_ID]: this.getBoardId(board)
                   },
                   { ...query })
  }

  filterChannelsByActiveState = memoize((targets: Array<ChannelInfo>): Array<ChannelInfo> => {
    return targets.filter(c => c.state === TargetState.Board)
  })

  sortChannelsByMembersCount = memoize((targets: Array<ChannelInfo>): Array<ChannelInfo> => {
    return targets.sort((a, b) => b.members_count - a.members_count)
  })

  async requestBoardsStat(): Promise<void> {
    try {
      const { boards } = await BoardsApi.getBoardsStat('tg', {})
      const timeFormat = 'YYYY-MM-DDT00:00'
      const fromTo = fromToTicks(moment().add(-7, 'days').format(timeFormat), moment().format(timeFormat), { format: timeFormat })

      boards.forEach(b => b.targets_stat.forEach(t => {
        seriesByMetricInList(this.$i18n, t.detailed_stat, [ 'all_enter', 'all_leave' ], fromTo)
          .calcSeries()
          .then((s) => {
            this.$set(this.targetsStatistics, t.id, s)
          })
      }))

    } catch (error: any) {
      errorNotification(error)
    }
  }

  botStateClick(board: SlimBoardInfo): void {
    this.handleEntityStateClick({ state: board.test_state, entity: 'board' })

    this.activeBoard = {
      board: this.getBoardId(board.board),
      permissions: [],
      title: board.title
    }
  }

  mounted(): void {
    this.requestBoardsStat()
  }
}
