import BaseApi from '@/includes/services/BaseApi'
import { errorNotification } from "@/includes/services/NotificationService";
import { BaseBoardRequest } from "@/includes/types/Api.types";

import { Distribution, FullDistribution } from 'piramis-base-components/src/components/Distributions/Distribution'
import { DistributionState } from 'piramis-base-components/src/components/Distributions/DistributionEditor/types'

import { instanceToPlain, plainToInstance } from 'class-transformer'

export class DistributionService {
    static createDistribution(type:string, body: BaseBoardRequest<{ distribution: Distribution }>) {
        return BaseApi.sendRequest<{ data: FullDistribution }>(type, 'createdistribution', {
            board_key: body.board_key,
            ...instanceToPlain(body.distribution)
        })
            .then(res => plainToInstance(FullDistribution, res.data))
            .catch(errorNotification)
    }

    static editDistribution(type:string, body: BaseBoardRequest<{ distribution: Distribution }>) {
        return BaseApi.sendRequest<{ data: FullDistribution }>(type, 'editdistribution', {
            board_key: body.board_key,
            ...instanceToPlain(body.distribution)
        })
            .then(res => plainToInstance(FullDistribution, res.data))
            .catch(errorNotification)
    }

    static getDistributions(type:string, body: BaseBoardRequest<{ limit?: number, offset?: number, state?: DistributionState }>) {
        return BaseApi.sendRequest<{ items: Array<FullDistribution> }>(type, 'getdistributions', body)
            .then(data => data.items.map(item => plainToInstance(FullDistribution, item)))
            .catch((error) => {
                errorNotification(error)

                return []
            })
    }

    static getDistribution(type:string, body: BaseBoardRequest<{ id:number }>) {
        return BaseApi.sendRequest<{ data: FullDistribution }>(type, 'getdistribution', body)
            .then(data => plainToInstance(FullDistribution, data.data))
            .catch(errorNotification)
    }

    static preCheckDistribution(type: string, body: BaseBoardRequest<{ conditions: Distribution['conditions'] }>) {
        return BaseApi.sendRequest<{ count: number }>(type, 'PreCheckDistribution', body)
    }

    static cancelDistribution(type:string, body: BaseBoardRequest<{ id:number }>) {
        return BaseApi.sendRequest<void>(type, 'cancelDistribution', body)
    }
}
