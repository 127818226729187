







































import { IntegrationNodeCustom } from './IntegrationCustom';
import { ChatkeeperIntegrationProxy } from './ChatkeeperIntegration';

import { ButtonView } from 'piramis-base-components/src/components/Flow/new/components'
import { SocketNames } from "piramis-base-components/src/components/Flow/new/components/Socket/SocketsHelper";
import { SampleNodeMixin } from 'piramis-base-components/src/components/Flow/new/logic/Nodes';

import { Component, Prop } from 'vue-property-decorator'

@Component({
  components: {
    ButtonView
  },
  data() {
    return {
      SocketNames,
      ChatkeeperIntegrationProxy,
    }
  }
})
export default class IntegrationSampleNodeCustom extends SampleNodeMixin {
  @Prop() node!: IntegrationNodeCustom

  get nodeInvalid() {
    if (this.node.currentIntegration instanceof ChatkeeperIntegrationProxy) {
      return !this.node.currentIntegration.token
    }

    return false
  }

  get type() {
    if (this.node.currentIntegration instanceof ChatkeeperIntegrationProxy) {
      return this.i18n.t(this.node.currentIntegration.integrationTitleKey(this.node.currentIntegration.data.type))
    }

    return ''
  }
}
