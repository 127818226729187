




































































































import { ChangePointsRequest } from "../ChangePointsRequest/ChangePointsRequest";
import { ChangeReputationRequest } from "../ChangeReputationRequest/ChangeReputationRequest";

import NumberInput from "piramis-base-components/src/components/Pi/fields/NumberInput/NumberInput.vue";
import TextInput from "piramis-base-components/src/components/Pi/fields/TextInput/TextInput.vue";
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import TimeUnitInput from "piramis-base-components/src/components/Pi/fields/TimeUnitInput/TimeUnitInput.vue";
import SwitchInput from "piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue";
import NestedContent from "piramis-base-components/src/components/Pi/components/NestedContent.vue";

import { Component, VModel, Mixins } from 'vue-property-decorator';
import { snakeCase } from "lodash";

@Component({
  data() {
    return {
      ChangePointsRequest
    }
  },
  components: {
    NumberInput,
    TextInput,
    TimeUnitInput,
    SwitchInput,
    NestedContent
  }
})
export default class ChangeActivitySettings extends Mixins(ModelSetter) {
  @VModel() model!: ChangeReputationRequest | ChangePointsRequest

  get type() {
    return snakeCase(this.model.type)
  }
}
