import buttonView from './FormButton.vue'
import setupView from './FormButtonSetupView.vue'
import { ProjectButtonTypes } from '@/components/ProjectButtons/ProjectButtonTypes'

import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import BaseButtonBuilder
    , { BaseButtonViewData } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/index'
import { BaseButton } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/types'

import { Guid } from 'guid-typescript'
import i18n from 'vue-i18n'
import { plainToInstance } from 'class-transformer'

export default class FormButtonButtonBuilder extends BaseButtonBuilder {
    type = ProjectButtonTypes.FormButton
    titleKey = ProjectButtonTypes.FormButton

    setupView = setupView
    buttonView = buttonView

    data = new FormButtonData(this.$i18n.t('initial_text_message_editor_buttons').toString())

    constructor(i18n: i18n, getFormsFn: () => Promise<Array<SelectOptionData>>) {
        super(i18n, { getFormsFn })
    }

    icon = '📎'

    create(): BaseButton {
        return plainToInstance(FormButton, {
            ...this.data,
            type: ProjectButtonTypes.FormButton,
            guid: Guid.create().toString(),
        })
    }

    getData(button?: FormButton): FormButtonData {
        return new FormButtonData(button ? button : this.$i18n.t('initial_text_message_editor_buttons').toString())
    }
}

export class FormButtonData implements BaseButtonViewData {
    text = ''
    key!: null | string

    constructor(data: FormButton | string) {
        if(data instanceof FormButton || typeof data === 'object') {
            const { text, key } = data

            this.text = text
            this.key = key
        } else {
            this.text = data
        }
    }
}

export class FormButton implements BaseButton {
    type!: ProjectButtonTypes.FormButton
    guid!: string
    text!: string
    key!: string
}
