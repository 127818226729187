import { SendMessageRequestBuilder } from "./SendMessageRequest/SendMessageRequestBuilder";
import { ChangePointsRequestBuilder } from "./ChangePointsRequest/ChangePointsRequestBuilder";
import { ChangeReputationRequestBuilder } from "./ChangeReputationRequest/ChangeReputationRequestBuilder";
import { ChangeAchievementsRequestBuilder } from "./ChangeAchievements/ChangeAchievementsRequestBuilder";
import { RunTriggerRequestBuilder } from "./RunTrigger/RunTriggerRequestBuilder";
import {
    CompleteCaptchaRequestBuilder
} from "@/components/Flow/Nodes/Additionally/IntegrationCustom/ChatkeeperIntegration/CompleteCaptcha/CompleteCaptchaRequestBuilder";
import { ChatkeeperRequestTypeEnum } from "./types";
import { ChatkeeperIntegrationRequest } from "./IntegrationRequest/ChatkeeperIntegrationRequest";
import { ChatkeeperConfig, IntegrationService } from "../types";
import IntegrationApi from "@/includes/Api/Integration.api";
import { errorNotification, successNotification } from "@/includes/services/NotificationService";
import store from "@/store/store";
import i18n from "@/i18n/i18n";

import { keyBy, snakeCase } from "lodash";
import { instanceToInstance } from "class-transformer";
import Vue from "vue";

export class ChatkeeperIntegrationProxy implements IntegrationService<ChatkeeperIntegrationRequest> {
    backup!: ChatkeeperIntegrationRequest

    data!: ChatkeeperIntegrationRequest

    static chatkeeperConfig: { data: ChatkeeperConfig | null } = Vue.observable({
        data: null
    })
    static chatkeeperConfigRequestPromise: Promise<void> | null = null
    configIsLoading = false

    private _token = ''

    constructor(data: ChatkeeperIntegrationRequest) {
        this.backup = data
        this.data = data

        this.token = data.token

        if (data.type === ChatkeeperRequestTypeEnum.ChangeAchievements || data.type === ChatkeeperRequestTypeEnum.RunTrigger) {
            this.getChatkeeperConfig()
        }
    }

    static builders = keyBy([
        SendMessageRequestBuilder,
        ChangePointsRequestBuilder,
        ChangeReputationRequestBuilder,
        ChangeAchievementsRequestBuilder,
        RunTriggerRequestBuilder,
        CompleteCaptchaRequestBuilder,
    ], 'type')

    get token() {
        return this._token
    }

    set token(value: string) {
        this._token = this.data.token = value.trim()
    }

    get requestType() {
        return this.data.type
    }

    set requestType(type: ChatkeeperRequestTypeEnum) {
        if (this.backup && this.backup.type === type) {
            this.data = instanceToInstance(this.backup)
            this.data.token = this.token
        } else {
            const builder = ChatkeeperIntegrationProxy.builders?.[type]

            if (builder) {
                this.data = instanceToInstance(builder.getModel())
                this.data.token = this.token
            } else {
                this.data.type = type
            }
        }
    }

    getData() {
        return this.data
    }

    integrationTitleKey(type: ChatkeeperRequestTypeEnum) {
        return `integration_type_${ snakeCase(type).toLowerCase() }`
    }

    getChatkeeperConfig() {
        this.configIsLoading = true

        if(!ChatkeeperIntegrationProxy.chatkeeperConfigRequestPromise) {
            ChatkeeperIntegrationProxy.chatkeeperConfigRequestPromise = IntegrationApi.getChatkeeperConfig('tg', {
                board_key: store.getters.activeBoard.board,
                token: this.token
            })
                .then(res => {
                    Vue.set(ChatkeeperIntegrationProxy.chatkeeperConfig, 'data', res.config)
                })
                .catch(errorNotification)
                .finally(() => {
                    this.configIsLoading = false

                    ChatkeeperIntegrationProxy.chatkeeperConfigRequestPromise = null
                })
        } else {
            ChatkeeperIntegrationProxy.chatkeeperConfigRequestPromise.then(() => {})
        }
    }

}