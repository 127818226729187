import { ChatkeeperIntegrationRequest } from "../IntegrationRequest/ChatkeeperIntegrationRequest";
import { ChatkeeperRequestTypeEnum } from "../types";

export class ChangePointsRequest extends ChatkeeperIntegrationRequest {
  type: ChatkeeperRequestTypeEnum = ChatkeeperRequestTypeEnum.ChangePoints;

  value!: number

  lower_limit!: number
  upper_limit!: number

  reason!: string

  timeout!: number
  disable_negative!: boolean
}