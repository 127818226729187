













































































































// import TelegramPostPreview from './TelegramPostPreview.vue'
import { PostService } from '@/includes/services/PostService'
import store from '@/store/store'
import router from '@/router'
import { SlimBoardInfo } from '@/includes/types/Board.types'
import i18n from '@/i18n/i18n'
import { OrderedPostData, OrderedPostType } from '@/includes/types/Post.types'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { canAccessPublished, canEditAndDeletePost, canViewOtherPosts } from '@/includes/PermissionHelper'
import { CalendarPostsSource } from '@/includes/types/CalendarPostsSource'

import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { PostState } from 'piramis-base-components/src/shared/modules/posting/types'
import TelegramPostPreview from 'piramis-base-components/src/shared/modules/posting/PostPreview/TelegramPostPreview.vue'
import {
  isPostPinned, isPostWithDisableNotify,
  isPostWithProtectContent
} from 'piramis-base-components/src/shared/modules/posting/PostPreview/includes/helpers'
import TokenId from "piramis-base-components/src/shared/utils/TokenId";

import Vue from 'vue'
import Component from 'vue-class-component'
import moment from 'moment'
import { groupBy, sortBy } from 'lodash'
import { Watch } from 'vue-property-decorator'

enum FeedCourse {
  Lower = 'Lower',
  Greatest = 'Greatest',
  Initial = 'Initial'
}

@Component({
  methods: { isPostWithDisableNotify, isPostPinned, isPostWithProtectContent },
  components: {
    TelegramPostPreview
  },
  data() {
    return {
      moment,
      i18n
    }
  }
})
export default class WebComponent extends Vue {
  loading = false

  LowerCanBeMore = true

  GreatestCanBeMore = true

  posts: Array<{ time: string, group: Array<OrderedPostData> }> = []

  firstLoad = true

  postsTemplateKey = 0

  emptyFeed = false

  //todo
  @Watch('requestType', { deep: true })
  onCalendarQueryChange():void {
    if (this.board) {
      this.getInitialPosts()
    }
  }

  get board(): SlimBoardInfo | undefined {
    const board = store.state.boardsState.boards

    if (board) {
      return board.find((board) => TokenId.getId(board.board) === router.currentRoute.params[ EntityTypes.BOT_ID ])
    }
  }

  get boardKey() {
    return this.board!.board
  }

  postRunTime(post:OrderedPostData):string {
    if (Array.isArray(post.run_time)) {
      return post.run_time[0]
    }

    return post.run_time
  }

  getTargetChannels(targets: Array<number>): Array<SelectOptionData> {
    return store.getters.boardTargetOptions.filter((channel) => targets.includes(channel.value))
  }

  editPost(post:OrderedPostData): void {
    PostService.getPostInfo('tg', {
      board_key: this.boardKey,
      post_key: post.key
    })
      .then(({ data }) => {
        const { state } = data.post
        const { type } = post.post.message

        if (state === PostState.New) {
          this.gotoPost(post.key, 'edit-suggest', type.toLowerCase())
        }
        if (state === PostState.Active) {
          this.gotoPost(post.key, 'edit', type.toLowerCase())
        }
        if (state === PostState.Complete) {
          this.gotoPost(post.key, 'edit-published', type.toLowerCase())
        }
      })
      .catch(errorNotification)
  }

  gotoPost(postId: string, actionType: 'edit-suggest' | 'edit' | 'edit-published', postType: string) {
    router.push({
      name: 'post',
      params: {
        actionType
      },
      query: {
        type: postType,
        postId
      }
    })
  }

  isEditButtonVisible(post: OrderedPostData):boolean {
    const permissions = store.state.boardsState.activeBoard?.permissions

    if (post.state === PostState.Deleted) {
      return false
    } else {
      if (post.state === PostState.Complete) {
        return canAccessPublished(permissions) && canViewOtherPosts(permissions)
      }

      if (post.state === PostState.Active) {
        return canEditAndDeletePost(permissions) && canViewOtherPosts(permissions)
      }

      if (post.state === PostState.New) {
        return true
      }

      return false
    }
  }

  isRemoveButtonVisible(post:OrderedPostData):boolean {
    const permissions = store.state.boardsState.activeBoard?.permissions
    const viewType = router.app.$route.query.calendar as CalendarPostsSource

    if (viewType === CalendarPostsSource.Deleted || post.state === PostState.Rejected) {
      return false
    }

    if (post.state === PostState.Active) {
      return canEditAndDeletePost(permissions) && canViewOtherPosts(permissions)
    }

    if (post.state === PostState.Complete) {
      return canAccessPublished(permissions) && canViewOtherPosts(permissions)
    }

    return false
  }

  removePost(post: OrderedPostData, groupTime:string): void {
    if (post.state === PostState.New) {
      PostService.deleteSuggestPost('tg', {
        post_key: post.key,
        board_key: this.boardKey
      })
    } else {
      this.$confirm({
        title: i18n.t('planner_popup_title_remove_warn').toString(),
        content: i18n.t('planner_popup_remove_warn').toString(),
        okText: i18n.t('accept').toString(),
        okType: 'danger',
        cancelText: i18n.t('reject').toString(),
        onOk: () => {
          const currentGroup = this.posts.find((group) => group.time === groupTime)
          const currentGroupIndex = this.posts.findIndex((group) => group.time === groupTime)
          const removedPost = currentGroup!.group.findIndex((groupPost) => groupPost.key === post.key)
          PostService.getPostInfo('tg', {
            board_key: this.boardKey,
            post_key: post.key
          })
            .then(({ data }) => {
              const { state } = data.post

              if (state === PostState.Active) {
                PostService.deletePost('tg', { board_key: this.boardKey, post_key: post.key })
                  .then(() => {
                    currentGroup!.group.splice(removedPost, 1)

                    if (!currentGroup!.group.length) {
                      this.posts.splice(currentGroupIndex, 1)
                    }

                    successNotification()
                  })
                  .catch(errorNotification)
              }

              if (state === PostState.Complete || state === PostState.Error) {
                PostService.deletePublishedPost('tg', { board_key: this.boardKey, post_key: post.key })
                  .then(() => {
                    currentGroup!.group.splice(removedPost, 1)

                    if (!currentGroup!.group.length) {
                      this.posts.splice(currentGroupIndex, 1)
                    }

                    successNotification()
                  })
                  .catch(errorNotification)
              }
            })
            .catch(errorNotification)
        },
        centered: true
      })
    }

  }

  processItems(posts: Array<OrderedPostData>, arg: FeedCourse): void {
    const sorted = sortBy(posts, (i) => {
      if (Array.isArray(i.run_time)) {
        return i.run_time[0]
      }

      return i.run_time
    })

    const grouped = Object.entries(groupBy(sorted, (i) => {
      if (Array.isArray(i.run_time)) {
        return i.run_time[0].split(' ')[0]
      }

      return i.run_time
    }))
      .map(([ time, group ]) => {
        return {
          time,
          group
        }
      })

    const preFirstPostKey = this.firstLoad ? sorted[0].key : this.posts[0].group[0].key

    if (arg === FeedCourse.Lower) {
      this.posts.unshift(...grouped)
    } else {
      this.posts.push(...grouped)
    }

    if (arg === FeedCourse.Lower) {
      this.$nextTick(() => {
        (this.$refs['post-feed'] as HTMLElement)!.scrollTo({
          top: document.getElementById(`post-${ preFirstPostKey! }`)!.parentElement!.offsetTop,
          left: 0,
          behavior: 'auto'
        })
      })
    }

    this.firstLoad = false

    this.$nextTick(() => {
      if (arg === FeedCourse.Lower) {
        this.postsTemplateKey += 1
      }
      this.loading = false
    })
  }

  getPosts(postKey: string, arg: FeedCourse.Lower | FeedCourse.Greatest, requestType: OrderedPostType) {
    this.loading = true

    PostService.getOrderedPosts('tg', {
      board_key: this.boardKey,
      post_key: postKey,
      type: requestType,
      order: arg
    })
      .then(({ posts }) => {
        if (posts.length && posts.length === 10) {
          this.processItems(posts, arg)
        } else if (posts.length && posts.length < 10) {
          this.processItems(posts, arg)

          this[`${ arg }CanBeMore`] = false
          this.loading = false
        } else {
          this[`${ arg }CanBeMore`] = false
          this.loading = false
        }
      })
  }

  get requestType():OrderedPostType {
    const viewType = router.app.$route.query.calendar as CalendarPostsSource

    switch (viewType) {
      case CalendarPostsSource.Schedule:
        return OrderedPostType.ActiveAndCompleted
      case CalendarPostsSource.Deleted:
        return OrderedPostType.Deleted
      case CalendarPostsSource.Suggested:
        return OrderedPostType.Suggest
      default:
        console.error(`Unknown CalendarPostsSource type: ${ viewType }`)
        return OrderedPostType.ActiveAndCompleted
    }
  }

  mounted(): void {
    this.$nextTick(() => {
      const customViewWrapper = document.querySelector('.post-feed-view')

      if (customViewWrapper) {
        customViewWrapper.addEventListener('scroll', (ev) => {
          if (!this.loading) {
            if (customViewWrapper.scrollTop === 0 && this.LowerCanBeMore) {
              customViewWrapper.scrollTop += 150
              const firstPostKey = this.posts[0].group[0].key
              this.getPosts(firstPostKey, FeedCourse.Lower, this.requestType)
            }

            if (customViewWrapper.scrollHeight - Math.abs(customViewWrapper.scrollTop) ===
              customViewWrapper.clientHeight && this.GreatestCanBeMore) {
              const lastDate = this.posts.length - 1
              const lastGroupPost = this.posts[lastDate].group.length - 1

              const lastPostKey = this.posts[lastDate].group[lastGroupPost].key
              this.getPosts(lastPostKey, FeedCourse.Greatest, this.requestType)
            }
          }
        })
      }
    })
  }

  created(): void {
    const context = this
    const recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'https://telegram.org/js/widget-frame.js')
    document.head.appendChild(recaptchaScript)
    recaptchaScript.onload = function () {
      (window as any).TWidgetPost.init()

      context.getInitialPosts()

    }
  }

  resetValues():void {
    this.LowerCanBeMore = true
    this.GreatestCanBeMore = true
    this.posts = []
    this.firstLoad = true
    this.postsTemplateKey = 0
    this.emptyFeed = false
  }

  getInitialPosts():void {
    this.loading = true
    this.resetValues()

    PostService.getOrderedPosts('tg', {
      board_key: this.boardKey,
      type: this.requestType,
      order: 'Initial'
    })
      .then(({ posts }) => {
        this.posts = []
        if (!posts.length) {
          this.emptyFeed = true
          this.loading = false
        } else {
          this.processItems(posts, FeedCourse.Initial)
        }
      })
      .catch(errorNotification)
      .finally(() => {
        this.loading = false
      })
  }
}
