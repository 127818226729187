import BaseApi from '@/includes/services/BaseApi'
import {
    ChannelSlot,
    ChannelInfo,
    ChannelConfig,
    TargetState,
    TestChannelStateCode, SlimBoardInfo
} from '@/includes/types/Board.types'
import {
    ClearUsersGetActiveRequestResponse,
    ClearUsersRequest,
    ClearUsersRequestBody,
} from '../types/Channel.types'
import { TargetFullStat } from '@/includes/types/TargetStat.types'
import { BaseBoardRequest } from "@/includes/types/Api.types";

export class ChannelService {
    static async updateChannelState(type: string, body: BaseBoardRequest<{ channel_id: number, new_state: TargetState }>) {
        return BaseApi.sendRequest<{ board: Omit<SlimBoardInfo, 'weight' | 'channels' | ''>}>(type, 'updatechannelstate', body)
    }

    static async getChannelConfig(type: string, body: BaseBoardRequest<{ channel_id: number }>) {
        return BaseApi.sendRequest<ChannelInfo>(type, 'getChannelConfig', body)
    }

    static async setChannelConfig(type: string, body: BaseBoardRequest<{ channel_id: number, config: ChannelConfig }>) {
        return BaseApi.sendRequest<ChannelInfo>(type, 'setChannelConfig', body)
    }

    static async getChannelStat(type: string, body: BaseBoardRequest<{ target_id: number, interval: any }>) {
        return BaseApi.sendRequest<{ data: TargetFullStat }>(type, 'getchannelstat', body)
    }

    static async testChannel(type: string, body: BaseBoardRequest<{ channel_id: number }>) {
        return BaseApi.sendRequest<{status: TestChannelStateCode}>(type, 'testchannel', body)
    }

    static async getChannelSlots(type: string, body: BaseBoardRequest<{ channel_ids: Array<number> }>) {
        return BaseApi.sendRequest<{items: Array<ChannelSlot>}>(type, 'getchannelslots', body)
    }

    static async clearUsersRequest(type: string, body: ClearUsersRequestBody) {
        return BaseApi.sendRequest<ClearUsersGetActiveRequestResponse | ClearUsersRequest>(type, 'ClearUsersRequest', body)
    }

    static async deleteChannel(type: string, body: BaseBoardRequest<{ id: number }>) {
        return BaseApi.sendRequest(type, 'deletechannel', body)
    }
}
