import { ChatkeeperRequestTypeEnum } from "@/components/Flow/Nodes/Additionally/IntegrationCustom/ChatkeeperIntegration/types";
import { ChangePointsRequest } from "./ChangePointsRequest";
import { ChatkeeperIntegrationRequestBuilder } from "../IntegrationRequest/ChatkeeperIntegrationRequestBuilder";

import { plainToInstance } from "class-transformer";

export const ChangePointsRequestBuilder = new class ChangePointsRequestBuilder extends ChatkeeperIntegrationRequestBuilder{
    type: ChatkeeperRequestTypeEnum = ChatkeeperRequestTypeEnum.ChangePoints;

    settings = () => import('../components/ChangeActivitySettings.vue')
    preview = () => import('../components/ChangeActivityPreview.vue')

    getModel() {
        return plainToInstance(ChangePointsRequest, {
            type: ChatkeeperRequestTypeEnum.ChangePoints,
            token: '',
            value: 0,
            lower_limit: null,
            upper_limit: null,
            reason: '',
            timeout: 0,
            disable_negative: false,
        })
    }
}
