import { ChatkeeperIntegrationRequest } from "../IntegrationRequest/ChatkeeperIntegrationRequest";
import { ChatkeeperRequestTypeEnum } from "../types";

import MessageEditorWithMediaData
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types";

import { Type } from "class-transformer";

export class SendMessageRequest extends ChatkeeperIntegrationRequest {
  type: ChatkeeperRequestTypeEnum = ChatkeeperRequestTypeEnum.SendMessage;

  @Type(() => MessageEditorWithMediaData)
  message!: Array<MessageEditorWithMediaData>
}