import BaseApi from '@/includes/services/BaseApi'
import {
    CreatedPost,
    MessageVariants,
    OrderedPostData,
    OriginalPostResponse,
    ProcessSuggestPostAction,
    RequestPost,
    RequestPostDraft,
    Style
} from '@/includes/types/Post.types'

import { PollPost, IPostMessage, QuizPost, Schedule, SplitType, PayedPostMessage } from 'piramis-base-components/src/shared/modules/posting/types'

import { AxiosRequestConfig } from 'axios'
import { BaseBoardRequest } from '../types/Api.types'

export class PostService {
    static async createPost(type: string, body: RequestPost | RequestPostDraft | Omit<RequestPost, 'schedule'>) {
        return BaseApi.sendRequest<{ post: CreatedPost }>(type, 'createPost', body)
    }

    static async savePost(type: string, body: any) {
        return BaseApi.sendRequest(type, 'savePost', body)
    }

    static async deletePost(type: string, body: any) {
        return BaseApi.sendRequest(type, 'deletePost', body)
    }

    static async getPosts(type: string, body: any) {
        return BaseApi.sendRequest<{ posts: Array<CreatedPost> }>(type, 'getPosts', body)
    }

    static async getOriginalPost(type: string, body: BaseBoardRequest<{ post_key: string }>) {
        return BaseApi.sendRequest<{ post: OriginalPostResponse }>(type, 'getOriginalPost', body)
    }

    static async getSavedPosts(type: string, body: any) {
        return BaseApi.sendRequest(type, 'getSavedPosts', body)
    }

    static async testPost(type: string, body: { post: IPostMessage | PollPost | QuizPost | PayedPostMessage, style: Style, board_key: string }): Promise<any> {
        return BaseApi.sendRequest(type, 'testPost', body)
    }

    static async getPostInfo(type: string, body: { post_key: string, board_key: string }): Promise<any> {
        return BaseApi.sendRequest(type, 'getpostinfo', body)
    }

    static async movePost(type: string, body: BaseBoardRequest<{ post_key: string, time: string }>): Promise<any> {
        return BaseApi.sendRequest(type, 'movepost', body)
    }

    static async deleteSavedPost(type: string, body: BaseBoardRequest<{ post_key: string }>): Promise<{ post_key: { token_id: number, token_code: string } }> {
        return BaseApi.sendRequest(type, 'deletesavedpost', body)
    }

    static async getScheduleDates(type: string, body: BaseBoardRequest<{ schedule: Schedule }>) {
        return BaseApi.sendRequest<{ run_time: Array<string> }>(type, 'getscheduledates', body)
    }

    static async deletePublishedPost(type: string, body: BaseBoardRequest<{ post_key: string }>) {
        return BaseApi.sendRequest<{ post_key: string }>(type, 'deletepublishedpost', body)
    }

    static async editPublishedPost(type: string, body: BaseBoardRequest<{ post_key: string, message: Array<MessageVariants>, style: Style }>): Promise<{ post_key: string }> {
        return BaseApi.sendRequest(type, 'editpublishedpost', body)
    }

    static async getOrderedPosts(type: string, body: BaseBoardRequest<{ post_key?: string, order: string, type: string }>) {
        return BaseApi.sendRequest<{posts:Array<OrderedPostData>}>(type, 'getorderedposts', body)
    }

    static async suggestPost(type: string, body: RequestPost | RequestPostDraft | Omit<RequestPost, 'schedule'>): Promise<any> {
        return BaseApi.sendRequest(type, 'suggestpost', body)
    }

    static async getSuggestPosts(type: string, body: any): Promise<any> {
        return BaseApi.sendRequest(type, 'getsuggestposts', body)
    }

    static async getDeletedPosts(type: string, body: any): Promise<any> {
        return BaseApi.sendRequest(type, 'getdeletedposts', body)
    }

    static async applySuggestPost(type:string, body: BaseBoardRequest< { post_key:string, action: ProcessSuggestPostAction }>): Promise<any> {
        return BaseApi.sendRequest(type, 'applysuggestpost', body)
    }

    static async getNotifications(type:string) {
        return BaseApi.sendRequest<{count:number, suggest_posts: Array<{ board_key:string, post_key:string }>}>(type, 'getnotifications', undefined)
    }

    static async getSuggestPost(type:string, body: BaseBoardRequest<{ post_key:string }>): Promise<any> {
        return BaseApi.sendRequest(type, 'getsuggestpost', body)
    }

    static async deleteSuggestPost(type:string, body: BaseBoardRequest<{ post_key:string }>): Promise<any> {
        return BaseApi.sendRequest(type, 'deletesuggestpost', body)
    }

    static async getPostPreview(type:string,
                          body: BaseBoardRequest<{ post:CreatedPost['post']['message'], style: Style, apply_watermark:boolean }>,
                          config: AxiosRequestConfig = {}) {
        return BaseApi.sendRequest<{post: IPostMessage & {split_type: SplitType}}>(type, 'getpostpreview', body,  config)
    }

    static async editPost(type:string, body: any): Promise<any> {
        return BaseApi.sendRequest(type, 'editpost', body)
    }
}