import buttonView from './HiddenMessageButton.vue'
import setupView from './HiddenMessageButtonSetupView.vue'
import { ProjectButtonTypes } from '@/components/ProjectButtons/ProjectButtonTypes'

import BaseButtonBuilder, {
  BaseButtonViewData
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons'
import {
  BaseButton
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/types'

import VueI18n from 'vue-i18n'
import { plainToInstance } from 'class-transformer'
import { Guid } from 'guid-typescript'

export default class HiddenMessageButtonBuilder extends BaseButtonBuilder {
  type = ProjectButtonTypes.HiddenMessageButton

  titleKey = ProjectButtonTypes.HiddenMessageButton

  setupView = setupView
  buttonView = buttonView

  icon = '🌙'

  data = new HiddenMessageButtonData(this.$i18n.t('initial_text_message_editor_buttons').toString())

  constructor(i18n: VueI18n) {
    super(i18n)
  }

  getData(button?: HiddenMessageButton): HiddenMessageButtonData {
    if (button && button.daysCount === 0 && button.notEnoughSubscribedMessage === null) {
      this.titleKey = ProjectButtonTypes.SimpleHiddenMessageButton
    } else {
      this.titleKey = ProjectButtonTypes.HiddenMessageButton
    }

    return new HiddenMessageButtonData(button ? button : this.$i18n.t('initial_text_message_editor_buttons').toString())
  }

  create(): HiddenMessageButton {
    return plainToInstance(HiddenMessageButton, {
      ...this.data,
      type: ProjectButtonTypes.HiddenMessageButton,
      guid: Guid.create().toString(),
    })
  }
}

export class HiddenMessageButtonData implements BaseButtonViewData {
  subscribedMessage = ''
  notEnoughSubscribedMessage: string | null = ''
  unsubscribedMessage = ''
  daysCount = 31
  text!: string

  constructor(data: HiddenMessageButton | string) {
      if (data instanceof HiddenMessageButton || typeof data === 'object') {
        const {
          subscribedMessage,
          notEnoughSubscribedMessage,
          unsubscribedMessage,
          daysCount,
          text
        } = data

        this.subscribedMessage = subscribedMessage
        this.notEnoughSubscribedMessage = notEnoughSubscribedMessage
        this.unsubscribedMessage = unsubscribedMessage
        this.daysCount = daysCount
        this.text = text
      } else {
        this.text = data
      }
  }
}

export class HiddenMessageButton implements BaseButton {
  text!: string
  type!: ProjectButtonTypes.HiddenMessageButton
  guid!: string
  subscribedMessage!: string
  notEnoughSubscribedMessage!: string | null
  unsubscribedMessage!: string
  daysCount!: number
}