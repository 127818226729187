





































import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'
import { FormButtonData } from "@/components/ProjectButtons/FormButton";

import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'
import CreateIfEmpty from 'piramis-base-components/src/components/CreateIfEmpty.vue'

import { Component, Mixins, Prop, VModel } from 'vue-property-decorator'

@Component({
  components: { CreateIfEmpty }
})
export default class FormButtonSetupView extends Mixins<UseFields, ModelSetter>(UseFields, ModelSetter) {
  @VModel({ 'type': Object }) model!: FormButtonData

  @Prop({ 'type': Function }) readonly getFormsFn!: () => Array<SelectOptionData>

  gotoFormCreate() {
    this.$router.push({
      name: 'Full_form',
      params: {
        actionType: BaseItemViewAction.New,
      }
    })
  }
}
