

















import ConditionIcons from '@/components/FaceControl/ConditionIcons'
import {
  FaceControlBooleanConditions,
  FaceControlConditionTypes,
  FaceControlPercentConditions, FaceControlRegexpConditions
} from '@/includes/types/FaceControl.types'

import { Item, ListConfig } from 'piramis-base-components/src/components/Pi/components/DefaultList/logic/types'
import DefaultList from 'piramis-base-components/src/components/Pi/components/DefaultList/DefaultList.vue'
import AddEntityCard from "piramis-base-components/src/components/AddEntityCard.vue";

import Vue from 'vue'
import { snakeCase } from 'lodash'
import { Guid } from 'guid-typescript'
import { Component, Emit, Prop } from 'vue-property-decorator'

@Component({
  components: {
    AddEntityCard,
    DefaultList
  }
})
export default class AddFaceControlCondition extends Vue {
  @Prop() group!: string

  @Emit()
  addConditionGroup(conditionType: FaceControlConditionTypes): FaceControlConditionTypes {
    return conditionType
  }

  modal = false

  get conditionsGroupByName(): Array<Item> {
    return [ {
               title: this.$t(`condition_${ snakeCase(FaceControlPercentConditions.Name_english) }_title`).toString(),
               helpMessage: this.$t(`condition_${ snakeCase(FaceControlPercentConditions.Name_english) }_help_message`).toString(),
               icon: ConditionIcons[FaceControlPercentConditions.Name_english],
               key: FaceControlPercentConditions.Name_english
             },
             {
               title: this.$t(`condition_${ snakeCase(FaceControlPercentConditions.Name_russian) }_title`).toString(),
               helpMessage: this.$t(`condition_${ snakeCase(FaceControlPercentConditions.Name_russian) }_help_message`).toString(),
               icon: ConditionIcons[FaceControlPercentConditions.Name_russian],
               key: FaceControlPercentConditions.Name_russian
             },
             {
               title: this.$t(`condition_${ snakeCase(FaceControlPercentConditions.Name_rtl) }_title`).toString(),
               helpMessage: this.$t(`condition_${ snakeCase(FaceControlPercentConditions.Name_rtl) }_help_message`).toString(),
               icon: ConditionIcons[FaceControlPercentConditions.Name_rtl],
               key: FaceControlPercentConditions.Name_rtl
             },
             {
               title: this.$t(`condition_${ snakeCase(FaceControlPercentConditions.Name_han) }_title`).toString(),
               helpMessage: this.$t(`condition_${ snakeCase(FaceControlPercentConditions.Name_han) }_help_message`).toString(),
               icon: ConditionIcons[FaceControlPercentConditions.Name_han],
               key: FaceControlPercentConditions.Name_han
             },
             {
               title: this.$t(`condition_${ snakeCase(FaceControlRegexpConditions.Name_regex) }_title`).toString(),
               helpMessage: this.$t(`condition_${ snakeCase(FaceControlRegexpConditions.Name_regex) }_help_message`).toString(),
               icon: ConditionIcons[FaceControlRegexpConditions.Name_regex],
               key: FaceControlRegexpConditions.Name_regex
             },
             {
               title: this.$t(`condition_${ snakeCase(FaceControlBooleanConditions.Real_name) }_title`).toString(),
               helpMessage: this.$t(`condition_${ snakeCase(FaceControlBooleanConditions.Real_name) }_help_message`).toString(),
               icon: ConditionIcons[FaceControlBooleanConditions.Real_name],
               key: FaceControlBooleanConditions.Real_name
             }, ]
  }

  get conditionsGroupByDescription(): Array<Item> {
    return [ {
               title: this.$t(`condition_${ snakeCase(FaceControlPercentConditions.Description_english) }_title`).toString(),
               helpMessage: this.$t(`condition_${ snakeCase(FaceControlPercentConditions.Description_english) }_help_message`).toString(),
               icon: ConditionIcons[FaceControlPercentConditions.Description_english],
               key: FaceControlPercentConditions.Description_english
             },
             {
               title: this.$t(`condition_${ snakeCase(FaceControlPercentConditions.Description_russian) }_title`).toString(),
               helpMessage: this.$t(`condition_${ snakeCase(FaceControlPercentConditions.Description_russian) }_help_message`).toString(),
               icon: ConditionIcons[FaceControlPercentConditions.Description_russian],
               key: FaceControlPercentConditions.Description_russian
             },
             {
               title: this.$t(`condition_${ snakeCase(FaceControlPercentConditions.Description_rtl) }_title`).toString(),
               helpMessage: this.$t(`condition_${ snakeCase(FaceControlPercentConditions.Description_rtl) }_help_message`).toString(),
               icon: ConditionIcons[FaceControlPercentConditions.Description_rtl],
               key: FaceControlPercentConditions.Description_rtl
             },
             {
               title: this.$t(`condition_${ snakeCase(FaceControlPercentConditions.Description_han) }_title`).toString(),
               helpMessage: this.$t(`condition_${ snakeCase(FaceControlPercentConditions.Description_han) }_help_message`).toString(),
               icon: ConditionIcons[FaceControlPercentConditions.Description_han],
               key: FaceControlPercentConditions.Description_han
             },
             {
               title: this.$t(`condition_${ snakeCase(FaceControlRegexpConditions.Description_regex) }_title`).toString(),
               helpMessage: this.$t(`condition_${ snakeCase(FaceControlRegexpConditions.Description_regex) }_help_message`).toString(),
               icon: ConditionIcons[FaceControlRegexpConditions.Description_regex],
               key: FaceControlRegexpConditions.Description_regex
             },

             {
               title: this.$t(`condition_${ snakeCase(FaceControlBooleanConditions.References) }_title`).toString(),
               helpMessage: this.$t(`condition_${ snakeCase(FaceControlBooleanConditions.References) }_help_message`).toString(),
               icon: ConditionIcons[FaceControlBooleanConditions.References],
               key: FaceControlBooleanConditions.References
             } ]
  }

  get conditionsGroupByOther(): Array<Item> {
    return [ {
               title: this.$t(`condition_${ snakeCase(FaceControlBooleanConditions.Username) }_title`).toString(),
               helpMessage: this.$t(`condition_${ snakeCase(FaceControlBooleanConditions.Username) }_help_message`).toString(),
               icon: ConditionIcons[FaceControlBooleanConditions.Username],
               key: FaceControlBooleanConditions.Username
             },
             {
               title: this.$t(`condition_${ snakeCase(FaceControlBooleanConditions.Avatar) }_title`).toString(),
               helpMessage: this.$t(`condition_${ snakeCase(FaceControlBooleanConditions.Avatar) }_help_message`).toString(),
               icon: ConditionIcons[FaceControlBooleanConditions.Avatar],
               key: FaceControlBooleanConditions.Avatar
             },
    ]
  }

  get conditions(): ListConfig {
    return {
      data: [
        {
          group: {
            guid: Guid.create().toString(),
            titleKey: 'face_control_condition_group_name',
            groupItems: this.conditionsGroupByName
          }
        },
        {
          group: {
            guid: Guid.create().toString(),
            titleKey: 'face_control_condition_group_description',
            groupItems: this.conditionsGroupByDescription
          }
        },
        {
          group: {
            guid: Guid.create().toString(),
            titleKey: 'face_control_condition_group_other',
            groupItems: this.conditionsGroupByOther
          }

        }
        // {
        //   group: {
        //     guid: Guid.create().toString(),
        //     title: this.$t('face_control_condition_group_percent').toString(),
        //     groupItems: this.percentGroupItems
        //   }
        // },
        // {
        //   group: {
        //     guid: Guid.create().toString(),
        //     title: this.$t('face_control_condition_group_regex').toString(),
        //     groupItems: this.regexGroupItems
        //   }
        // }
      ],
      selectFn: (item: Item) => {
        this.addConditionGroup(item.key as FaceControlConditionTypes)
        this.modal = false
      }
    }
  }
}
