

















































import { UsersService } from '@/includes/services/UsersService'
import MetaDataService from '@/includes/services/MetaDataService'
import { InputSetups } from '@/mixins/input-setups'
import CSVExportService from '@/includes/services/CSVExportService'
import BotUsersCore from './BotUsersCore'
import { errorNotification } from '@/includes/services/NotificationService'

import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import BotUsers from 'piramis-base-components/src/components/BotUsers/BotUsers.vue'
import { BotUsersConfig, GetActivityReportResponse } from 'piramis-base-components/src/components/BotUsers/types'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { getNewPropertyIcon, getPropertyTranslation } from 'piramis-base-components/src/components/BotProperties/utils'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { PropertyFieldType } from 'piramis-base-components/src/components/BotProperties/components/Properties/types'
import TokenId from 'piramis-base-components/src/shared/utils/TokenId'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    PageTitle,
    BotUsers,
  },
  data() {
    return {
      TokenId
    }
  }
})
export default class Users extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  config: BotUsersConfig | null = null

  isExportBotUsersModalOpen = false

  exportProperties: Array<number> = []

  get propertiesOptions(): Array<SelectOptionData> {
    return this.$store.getters.propertiesList
      .filter(p => p.type !== PropertyFieldType.Files && !p.readonly && !p.automatic)
      .map(p => {
        return {
          label: getPropertyTranslation(this.$i18n, p.name ?? p.type),
          value: p.id,
          icon: {
            name: getNewPropertyIcon(p.type),
            iconPack: 'material-icons'
          }
        }
      })
  }

  exportToCsv(): void {
    CSVExportService.ExportBotUsers('tg', this.$store.state.boardsState.activeBoard!.board, this.exportProperties)
      .then(() => {
        this.exportProperties = []
        this.isExportBotUsersModalOpen = false
      })
      .catch(errorNotification)
  }

  goToProfile(id: number): void {
    this.$router.push({
      name: 'User_profile',
      params: {
        [ EntityTypes.BOT_ID ]: this.$route.params[ EntityTypes.BOT_ID ],
        user_id: id.toString()
      }
    })
  }

  getReports(utm?: string): Promise<GetActivityReportResponse> {
    const body = Object.assign({ board_key: this.$store.state.boardsState.activeBoard!.board }, ...[ utm ? { utm } : {} ])

    return new Promise(resolve => UsersService.getActivityReport('tg', body).then(({ data }) => resolve(data)))
  }

  async getUtmReports() {
    const body = { board_key: this.$store.state.boardsState.activeBoard!.board }

    return UsersService.getActivityByUtmReport('tg', body).then(({ data }) => data)
  }

  created(): void {
    this.config = {
      botUsersCore: new BotUsersCore(this.$i18n),
      getUtms: () => new Promise(resolve => {
        MetaDataService.getUtmSources('tg', { board_key: this.$store.state.boardsState.activeBoard?.board })
          .then(({ items }) => {
            resolve(items)
          })
      }),
      getUsersReports: this.getReports,
      getUtmReports: this.getUtmReports
    }
  }
}
