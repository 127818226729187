import BaseApi from "@/includes/services/BaseApi"
import { BoardConfig } from "@/includes/types/Board.types";
import { BaseBoardRequest } from "@/includes/types/Api.types";

import { GetActivityReportResponse } from 'piramis-base-components/src/components/BotUsers/types'
import { UserProfile } from 'piramis-base-components/src/components/BotUsers/components/Users/types'
import MessageEditorWithMediaData
	from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/types'
import { PropertyValueType, UserAttributes } from 'piramis-base-components/src/components/BotUserProfile/types'
import { Subscription } from "piramis-base-components/src/shared/types/BotUsers"

import { instanceToPlain } from 'class-transformer'

type UserLabels = Pick<BoardConfig, 'labels'> & { user_id: number }
type ResponsePropertyValue = { value: string | number | boolean  }

export class UsersService {
	static async getBotUsers(type: string, body: BaseBoardRequest<{ offset: number, limit: number, filter?: string, labels?: Array<number> }>) {
		return BaseApi.sendRequest<any>(type, 'getbotusers', body)
	}

	static async getProfile(type: string, body: BaseBoardRequest<{ id: string | number }>) {
		return BaseApi.sendRequest<{ data: UserProfile }>(type, 'getprofile', body)
	}

	static async deleteBotUserLabel(type: string, body: BaseBoardRequest<{ user_id: number, label_id: number }>) {
		return BaseApi.sendRequest<UserLabels>(type, 'deletebotuserlabel', body)
	}

	static async addBotUserLabel(type: string, body: BaseBoardRequest<{ user_id: number, label_id: number }>) {
		return BaseApi.sendRequest<UserLabels>(type, 'addbotuserlabel', body)
	}

	static async setBotUserProperty(type: string, body: BaseBoardRequest<{ user_id: number, property_id: number, value: PropertyValueType }>) {
		return BaseApi.sendRequest<ResponsePropertyValue>(type, 'setbotuserproperty', body)
	}

	static async deleteBotUserProperty(type: string, body: BaseBoardRequest<{ user_id: number, property_id: number }>) {
		return BaseApi.sendRequest<ResponsePropertyValue>(type, 'deletebotuserproperty', body)
	}

	static async createSubscription(type: string, body: BaseBoardRequest<{ user_id: number, channel_id: number, duration: number }>) {
		return BaseApi.sendRequest<{ data: Subscription }>(type, 'createsubscription', body)
	}

	static async deleteSubscription(type: string, body: BaseBoardRequest<{ user_id: number, channel_id: number }>) {
		return BaseApi.sendRequest(type, 'deletesubscription', body)
	}

	/**
	 * @param type
	 * @param body : { board_key: ключ доски, utm: опционально, id UTM метки }
	 */
	static async getActivityReport(type: string, body: BaseBoardRequest<{ utm?: string }>) {
		return BaseApi.sendRequest<{ data: GetActivityReportResponse }>(type, 'getactivityreport', body)
	}

	static async getActivityByUtmReport(type: string, body: BaseBoardRequest ) {
		return BaseApi.sendRequest<{ data: Record<string, Partial<GetActivityReportResponse>> }>(type, 'getactivitybyutmreport', body)
	}

	static async banUser(type, body: BaseBoardRequest<{ user_id: number }>) {
		return BaseApi.sendRequest(type, 'ban', body)
	}

	static async unbanUser(type, body: BaseBoardRequest<{ user_id: number }>) {
		return BaseApi.sendRequest(type, 'unban', body)
	}

	static async setUserAttribute(type, board_key: string, user_id: number, attribute: UserAttributes) {
		return BaseApi.sendRequest(type, 'setuserattribute', { board_key, user_id, ...attribute })
	}

	static async sendMessage(type, body: BaseBoardRequest<{ message: MessageEditorWithMediaData, target: number }>) {
		return BaseApi.sendRequest(type, 'sendmessage', instanceToPlain(body))
	}
}
