import BaseApi from '@/includes/services/BaseApi'
import { UtmSource } from '@/includes/types/MetaData.types'
import { MenuOptions } from '@/includes/types/MenuOptions.types'
import { BaseBoardRequest } from "@/includes/types/Api.types";

export default class MetaDataService {
    static async getUtmSources(type:string, body: BaseBoardRequest) {
        return BaseApi.sendRequest<{items: Array<UtmSource>}>(type, 'getutmsources', body)
    }

    static async getMenuOptions(type:string, body: BaseBoardRequest) {
        return BaseApi.sendRequest<MenuOptions>(type, 'getmenuoptions', body)
    }
}