







































import { UseFields } from 'piramis-base-components/src/components/Pi'

import { ValidationObserver } from 'vee-validate'
import { Component, Mixins, VModel } from 'vue-property-decorator'
import ModelSetter from 'piramis-base-components/src/Mixins/ModelSetter'
import { ShareTextButtonData } from '@/components/ProjectButtons/ShareTextButton'

@Component({
  components: {
    ValidationObserver,
  },
})
export default class ShareTextButtonSetupView extends Mixins<UseFields, ModelSetter>(UseFields, ModelSetter) {
  @VModel() model!: ShareTextButtonData
}
