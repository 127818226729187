import { ForwarMessageActionMode } from '@/includes/types/Post.types'
import Button
	from "piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons";
import CaptchaButtonBuilder from '@/components/ProjectButtons/Captcha'
import RunFlowButtonBuilder from '@/components/ProjectButtons/RunFlowButton'
import ShareTextButtonBuilder from '@/components/ProjectButtons/ShareTextButton'
import FormButtonButtonBuilder from '@/components/ProjectButtons/FormButton'
import HiddenMessageButtonBuilder from '@/components/ProjectButtons/HiddenMessageButton'
import SimpleHiddenMessageButtonBuilder from '@/components/ProjectButtons/SimpleHiddenMessageButton'
import HiddenCountedLinkButtonBuilder from '@/components/ProjectButtons/HiddenCountedLinkButton'
import HiddenCountedMessageButtonBuilder from '@/components/ProjectButtons/HiddenCountedMessageButton'
import HiddenSubscriberLinkButtonBuilder from '@/components/ProjectButtons/HiddenSubscriberLinkButton'
import CommentsButtonBuilder from '@/components/ProjectButtons/CommentsButton'

import { FieldData, SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'
import { MultiMessageEditorWithMediaData } from 'piramis-base-components/src/components/MultiMessageEditorWithMedia/types'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import LinkButtonBuilder
	from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Link/index'
import ShareButtonBuilder
	from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Share/index'
import BaseButtonBuilder
	from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/index'
import {
	ButtonBuildersGroups
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/logic/types'
import ReactionButtonBuilder from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Reaction'
import FingerDownReactionButtonBuilder
	from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/FingerDownReaction'
import FingerUpReactionButtonBuilder
	from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/FingerUpReaction'
import PalmReactionButtonBuilder
	from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/PalmReaction'
import TextButtonBuilder
	from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/TextButton/index";

import { Vue, Component } from 'vue-property-decorator'
import { mapActions } from 'vuex'

type newMoreButtons = { moreSimpleButton?: Array<BaseButtonBuilder>, moreProButtons?: Array<BaseButtonBuilder> }

@Component({
	data() {
		return {
			StepUnit
		}
	},
	methods: {
		...mapActions([ 'resolveFlowSelectOption' ])
	}
})
export class InputSetups extends Vue {
	resolveFlowSelectOption!: Promise<Array<SelectOption>>

	props = { i18n: this.$i18n }

	getNewDefaultButtons(moreButtons?: newMoreButtons) {
		return [
			new ButtonBuildersGroups(this.$t('field_simple_buttons_title').toString(),[
				new LinkButtonBuilder(this.$i18n),
				new ShareButtonBuilder(this.$i18n),
				new SimpleHiddenMessageButtonBuilder(this.$i18n),
				new ShareTextButtonBuilder(this.$i18n),
				new ReactionButtonBuilder(this.$i18n),
				new PalmReactionButtonBuilder(this.$i18n),
				new FingerUpReactionButtonBuilder(this.$i18n),
				new FingerDownReactionButtonBuilder(this.$i18n),
				...moreButtons && moreButtons.moreSimpleButton ? moreButtons.moreSimpleButton : [],
				new FormButtonButtonBuilder(this.$i18n, this.getFormsSelectOptions),
			]),
			new ButtonBuildersGroups(this.$t('field_pro_buttons_title').toString(),[
				new RunFlowButtonBuilder(this.$i18n, this.resolveFlowSelectOption),
				new CommentsButtonBuilder(this.$i18n, this.$store.state.boardsState.activeBoard?.title),
				new HiddenMessageButtonBuilder(this.$i18n),
				new HiddenCountedMessageButtonBuilder(this.$i18n),
				new HiddenSubscriberLinkButtonBuilder(this.$i18n, this.getArticlesSelectOptions),
				new HiddenCountedLinkButtonBuilder(this.$i18n, this.getArticlesSelectOptions),
				...moreButtons && moreButtons.moreProButtons ? moreButtons.moreProButtons : [],

			])
		]
	}

	get newCaptchaButton():any {
		return [
			new CaptchaButtonBuilder(this.$i18n),
		]
	}

	get newTextButton():any {
		return [
			new TextButtonBuilder(this.$i18n),
		]
	}

	get newPmButtons(): any {
		return [
			new RunFlowButtonBuilder(this.$i18n, this.resolveFlowSelectOption),
			new LinkButtonBuilder(this.$i18n),
			new ShareButtonBuilder(this.$i18n),
			new ShareTextButtonBuilder(this.$i18n),
			new FormButtonButtonBuilder(this.$i18n, this.getFormsSelectOptions),
		]
	}

	getFormsSelectOptions(): Promise<Array<SelectOptionData>> {
			if (this.$store.state.formsState.forms === null) {
				return this.$store.dispatch('getForms')
					.then(() => this.$store.getters.formsSelectOptions)
			}

			return this.$store.getters.formsSelectOptions
	}

	async getArticlesSelectOptions(): Promise<Array<SelectOptionData>> {
		if (this.$store.state.articlesState.articles === null) {
			try {
				await this.$store.dispatch('requestArticles')
				return this.$store.getters.articlesOptions
			} catch {
				return []
			}
		} else {
			return this.$store.getters.articlesOptions
		}
	}

	channelConfigSetup(args: FieldData): FieldData {
		args.model = this.$store.state.channelsState.activeChannel.config
		if (args.setter === undefined) {
			args.setter = (value: any): void => {
				this.$store.commit('pi/EXEC', {
					'key': args.key,
					'fn': () => {
						this.$set(args.model, args.key as string, value)
					},
				})
			}
		}
		return args
	}

	modelSetter(args: FieldData): FieldData {
		if ([ 'postfix_message', 'prefix_message', 'variants', 'message' ].includes(args.key) && !(args as FieldData & MultiMessageEditorWithMediaData).settingsStructure) {
			(args as FieldData & MultiMessageEditorWithMediaData).settingsStructure = {
				'disable_link_preview': false,
				'disable_notify': false,
				'pin': false,
				'remove_previous': false,
			}
		}

		args.setter = (value: any) => {
			if (value !== undefined) {
				this.$set(args.model, args.key, value)
			}
		}
		return args
	}

	get forwardMessageActionModeOptions(): Array<SelectOption> {
		return Object.values(ForwarMessageActionMode).map(mode => ({
			label: this.$t(`mode_option_${ mode.toLowerCase() }`),
			value: mode
		}))
	}
}
