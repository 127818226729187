import { ChatkeeperRequestTypeEnum } from "@/components/Flow/Nodes/Additionally/IntegrationCustom/ChatkeeperIntegration/types";
import { ChangeReputationRequest } from "./ChangeReputationRequest";
import { ChatkeeperIntegrationRequestBuilder } from "../IntegrationRequest/ChatkeeperIntegrationRequestBuilder";

import { plainToInstance } from "class-transformer";

export const ChangeReputationRequestBuilder = new class ChangeReputationRequestBuilder extends ChatkeeperIntegrationRequestBuilder {
    type: ChatkeeperRequestTypeEnum = ChatkeeperRequestTypeEnum.ChangeReputation;

    settings = () => import('../components/ChangeActivitySettings.vue')
    preview = () => import('../components/ChangeActivityPreview.vue')

    getModel() {
        return plainToInstance(ChangeReputationRequest, {
            type: ChatkeeperRequestTypeEnum.ChangeReputation,
            token: '',
            value: 0,
            lower_limit: null,
            upper_limit: null,
            reason: '',
        })
    }
}
