import BaseApi from '@/includes/services/BaseApi'
import { OutputData } from "@editorjs/editorjs"
import { BaseBoardRequest } from "@/includes/types/Api.types";

export class ArticleService {

    static async createArticle(type: string, body: BaseBoardRequest<{ title: string, article: any }>) {
        return BaseApi.sendRequest(type, 'createarticle', body)
    }

    static async deleteArticle(type: string, body: BaseBoardRequest<{ key: string }>) {
        return BaseApi.sendRequest(type, 'deletearticle', body)
    }

    static async getArticle(type: string, body: BaseBoardRequest<{ key: string }>) {
        return BaseApi.sendRequest(type, 'getarticle', body)
    }

    static async updateArticle(type: string, body: BaseBoardRequest<{ key: string, title: string, article: OutputData }>) {
        return BaseApi.sendRequest(type, 'updatearticle', body)
    }

    static async getArticlesInfo(type: string, body: BaseBoardRequest) {
        return BaseApi.sendRequest(type, 'getarticlesinfo', body)
    }

    static async getArticleRequest(type: string, body: {id: string}) {
        return BaseApi.sendRequest(type, 'getarticlerequest', body)
    }
}