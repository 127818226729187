import BaseApi from '@/includes/services/BaseApi'
import {
    InviteLinkStatReq,
    InviteLinksWithStat,
    InviteLinkToCreate,
    InviteLinkToEdit,
    InviteLinkWithKey
} from '@/includes/logic/InviteLinks/types'
import { BaseBoardRequest } from "@/includes/types/Api.types";

type ResponseLinks = { links: Array<InviteLinkWithKey> }
type ResponseLink = { link: InviteLinkWithKey }

export default class InviteLinksApi {
    static async getInviteLinks(type: string, body: BaseBoardRequest<{ target_id: number }>) {
        return BaseApi.sendRequest<ResponseLinks>(type, 'getinvitelinks', body)
    }

    static async createInviteLink(type: string, body: BaseBoardRequest<{ target_id: number } & InviteLinkToCreate>) {
        return BaseApi.sendRequest<ResponseLink>(type, 'createinvitelink', body)
    }

    static async setInviteLink(type: string, body: BaseBoardRequest<{ link_key: string } & InviteLinkToEdit>) {
        return BaseApi.sendRequest<ResponseLink>(type, 'setinvitelink', body)
    }

    static async deleteInviteLink(type: string, body: BaseBoardRequest<{ link_key:string }>) {
        return BaseApi.sendRequest<void>(type, 'deleteinvitelink', body)
    }

    static async getInviteLinksStat(type: string, body: BaseBoardRequest<{ target_id: number } & InviteLinkStatReq>) {
        return BaseApi.sendRequest<{ links: InviteLinksWithStat }>(type, 'getinvitelinksstat', body)
    }
}