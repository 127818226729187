











import { ChangeAchievementsRequest } from "./ChangeAchievementsRequest";

import { Component, Prop } from 'vue-property-decorator';
import Vue from "vue";
import VueI18n from "vue-i18n";
import { ChatkeeperIntegrationProxy } from "@/components/Flow/Nodes/Additionally/IntegrationCustom/ChatkeeperIntegration";

@Component({
})
export default class ChangeAchievementsRequestPreview extends Vue {
  @Prop() model!: ChangeAchievementsRequest

  @Prop() i18n!: VueI18n

  get achievementsLabel() {
    if (ChatkeeperIntegrationProxy.chatkeeperConfig.data) {
      return this.model.achievements.map(a => {
        return ChatkeeperIntegrationProxy.chatkeeperConfig.data?.achievements?.find(v => v.id === a)?.title ?? '-'
      })
    }

    return '-'
  }

}
