import buttonView from './HiddenCountedLinkButton.vue'
import setupView from './HiddenCountedLinkButtonSetupView.vue'
import { ProjectButtonTypes } from '@/components/ProjectButtons/ProjectButtonTypes'
import { HiddenTargetType } from '@/components/ProjectButtons/includes/helpers/HiddenTargetTypeOptions'

import BaseButtonBuilder, {
  BaseButtonViewData
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons'
import {
  BaseButton
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/types'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

import VueI18n from 'vue-i18n'
import { plainToInstance } from 'class-transformer'
import { Guid } from 'guid-typescript'

interface Props {
  getArticlesFn: () => Promise<Array<SelectOptionData>>
}

export default class HiddenCountedLinkButtonBuilder extends BaseButtonBuilder {
  type = ProjectButtonTypes.HiddenCountedLinkButton

  titleKey = ProjectButtonTypes.HiddenCountedLinkButton

  setupView = setupView
  buttonView = buttonView

  icon = '🔔 🕒'

  declare buttonProps: Props

  data = new HiddenCountedLinkButtonData(this.$i18n.t('initial_text_message_editor_buttons').toString())

  constructor(i18n: VueI18n, getArticlesFn: () => Promise<Array<SelectOptionData>>) {
    super(i18n, { getArticlesFn })
  }

  getData(button?: HiddenCountedLinkButton): HiddenCountedLinkButtonData {
    return new HiddenCountedLinkButtonData(button ? button : this.$i18n.t('initial_text_message_editor_buttons').toString())
  }

  create(): HiddenCountedLinkButton {
    return plainToInstance(HiddenCountedLinkButton, {
      ...this.data,
      type: ProjectButtonTypes.HiddenCountedLinkButton,
      guid: Guid.create().toString(),
    })
  }
}

export class HiddenCountedLinkButtonData implements BaseButtonViewData {
  emptyText = ''
  targetType = HiddenTargetType.Message
  targetValue = ''
  afterTargetType = HiddenTargetType.Message
  afterTargetValue = ''
  limit = 10
  grouped = false
  onlyUnique = true
  text = ''

  constructor(data: HiddenCountedLinkButton | string) {
      if (data instanceof HiddenCountedLinkButton || typeof data === 'object') {
        const {
          emptyText,
          targetType,
          targetValue,
          afterTargetType,
          afterTargetValue,
          limit,
          grouped,
          onlyUnique,
          text,
        } = data

        this.emptyText = emptyText
        this.targetType = targetType
        this.targetValue = targetValue
        this.afterTargetType = afterTargetType
        this.afterTargetValue = afterTargetValue
        this.limit = limit
        this.grouped = grouped
        this.onlyUnique = onlyUnique
        this.text = text
      } else {
        this.text = data
      }
  }
}

export class HiddenCountedLinkButton implements BaseButton {
  text!: string
  type!: ProjectButtonTypes.HiddenCountedLinkButton
  guid!: string
  emptyText!: string
  targetType!: HiddenTargetType
  targetValue!: string
  afterTargetType!: HiddenTargetType
  afterTargetValue!: string
  limit!: number
  grouped!: boolean
  onlyUnique!: boolean
}
