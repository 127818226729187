import BaseApi from '@/includes/services/BaseApi'
import { errorNotification } from '@/includes/services/NotificationService'
import { ProjectFlowConfig } from "@/components/Flow/Accessor/types";

import PostApi from 'piramis-base-components/src/plugins/AdminTemplate/includes/logic/Api/PostApi'
import { FlowData } from 'piramis-base-components/src/components/Flow/logic/types/GeneralTypes'
import { FlowTemplateNames } from 'piramis-base-components/src/components/FlowsTemplates/types'
import { GetUsersInfo } from 'piramis-base-components/src/models/getUserInfo/GetUserInfo'
import { ChannelInfoResponse } from 'piramis-base-components/src/models/CheckChannels'
import { FlowItem } from 'piramis-base-components/src/components/FlowsList/types'

import axios from 'axios'
import { plainToInstance } from 'class-transformer'
import { BaseBoardRequest } from "@/includes/types/Api.types";

export class FlowsService {
	static getFlowThumbnailData(type: string, body: { request: string }) {
		return BaseApi.sendRequest<FlowResponse>(type, 'getflowthumbnaildata', body)
	}

	static getBoardFlows(type: string, body: BaseBoardRequest) {
		return BaseApi.sendRequest<{ flows: Array<FlowItem> }>(type, 'getboardflows', body)
	}

	static createFlow(type: string, body: BaseBoardRequest<{ title: string, scheme: any }>) {
		return BaseApi.sendRequest<FlowItem>(type, 'createflow', body)
	}

	static getFlow(type: string, body: BaseBoardRequest<{ flow_key: string }>) {
		return  BaseApi.sendRequest<FlowResponse>(type, 'getflow', body)
	}

	static deleteFlow(type: string, body: BaseBoardRequest<{ flow_key: string }>) {
		return BaseApi.sendRequest<DeleteFlow>(type, 'deleteflow', body)
	}

	static updateFlow(type: string, body: BaseBoardRequest<{ flow_key: string, title: string, scheme: any }>) {
		return BaseApi.sendRequest<FlowResponse>(type, 'updateflow', body)
	}

	static getFlowThumbnail(type: string, body: BaseBoardRequest<{ flow_key: string }>): Promise<any> {
		return new Promise((resolve) => {
			axios.get(`${ PostApi.getApiUrl() }${ type }/getflowthumbnail?board_key=${ body.board_key }&flow_key=${ body.flow_key }`, {
				withCredentials: true,
			}).then((res) => {
				resolve(res)
			}).catch((error) => {
				errorNotification(error)
			})
		})
	}

	static checkChannels(type: string, body: BaseBoardRequest<{ values: Array<string> }>) {
		return BaseApi.sendRequest<CheckChannelsResponse>(type, 'checkchannels', body)
	}

	static getUsersInfo(type: string, body: BaseBoardRequest<{ values: Array<string | number> }>) {
		return BaseApi.sendRequest<GetUsersInfoResponse>(type, 'getusersinfo', body)
	}

	static createFlowLink(type: string, body: BaseBoardRequest<{ flow_key: string, utm: string }>) {
		return BaseApi.sendRequest<FlowItem>(type, 'createflowlink', body)
	}

	static renameFlow(type: string, body: BaseBoardRequest<{ key: string, title: string }>) {
		return BaseApi.sendRequest<FlowResponse>(type, 'renameflow', body)
	}

	static runTestingFlow(type: string, body: BaseBoardRequest<{ flow_key: string }>) {
		return BaseApi.sendRequest<void>(type, 'runflow', body)
	}

	static createTemplateFlow(type: string, body: CreateTemplateFlowRequestBody) {
		return BaseApi.sendRequest<{ flow: FlowItem }>(type, 'createtemplateflow', body)
	}

	// NEW FLOW REQUESTS
	static async newGetFlow(type: string, body: BaseBoardRequest<{ flow_key: string }>): Promise<{ instance: ProjectFlowConfig, plain: any }> {
		const { flow } = await BaseApi.sendRequest(type, 'GetFlow', body)

		return { instance: plainToInstance(ProjectFlowConfig, flow), plain: flow }
	}

	static async newGetUsersInfo(type: string, body: BaseBoardRequest<{ values: Array<string | number> }>): Promise<GetUsersInfo> {
		const info = await BaseApi.sendRequest(type, 'GetUsersInfo', body)

		return plainToInstance(GetUsersInfo, info)
	}

	static async getChannelInfo(type: string, body: BaseBoardRequest<{ values: Array<string> }>): Promise<ChannelInfoResponse> {
		const info = BaseApi.sendRequest(type, 'CheckChannels', body)
		return plainToInstance(ChannelInfoResponse, info)
	}
}

export type CreateTemplateFlowRequestBody = {
	board_key: string
	title: string
	template: FlowTemplateNames
	data?: {
		channel: number
	}
}

export type SubscriptionTarget = SuccessTarget | FailTarget

export type SuccessTarget = {
	status: true
	info : {
		id: number
		login: string
		title: string
		type: 'CHANNEL' | 'FORUM'
	}
}

export type FailTarget = {
	status: false
	error: string
}

export type SuccessGetUser = {
	value: string | number
	info: {
		id: number
		name: string
		login: string
		language: string
	}
}

export type CheckChannelsResponse = {
	data: Array<{value: string} & SubscriptionTarget>
}

export type GetUsersInfoResponse = {
	data: Array<SuccessGetUser>
}

export type FlowResponse = {
	flow: FlowData
}

export type DeleteFlow = {
	key: {
		token_id: number
		token_code: string
	}
}
