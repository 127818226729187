import { FlowsService, DeleteFlow, CreateTemplateFlowRequestBody } from '@/includes/services/FlowService'
import { errorNotification, successNotification } from '@/includes/services/NotificationService'
import { RootState } from '@/store/state'
import i18n from '@/i18n/i18n'
import router from '@/router'

import { FlowItem } from 'piramis-base-components/src/components/FlowsList/types'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { SelectOption } from 'piramis-base-components/src/logic/types'

import { Commit, Dispatch } from 'vuex'
import TokenId from "piramis-base-components/src/shared/utils/TokenId";

export default {
    resolveFlowSelectOption({ dispatch, state, getters }: { dispatch: Dispatch, state: RootState, getters: any }): Promise<SelectOption> {
      if (state.flowsState.flows === null) {
          return dispatch('requestFlows')
              .then(() => getters.flowOptions)
      } else {
          return getters.flowOptions
      }
    },
    requestFlows({ commit, state }: { commit: Commit, state: RootState }): Promise<Array<FlowItem>> {
        if (state.flowsState.getBoardFlowsPromise === null) {
            const getBoardFlowsPromise = FlowsService.getBoardFlows('tg', { board_key: state.boardsState.activeBoard!.board })
                .then((res) => {
                    commit('SET_FLOWS_TO_STORE', res.flows)
                    return res.flows
                })
                .catch((error) => {
                    errorNotification(error)
                    commit('SET_FLOWS_TO_STORE', [])

                    return []
                })
                .finally(() => {
                    commit('setObjectField', {
                        object: state,
                        objectPath: 'flowsState.getBoardFlowsPromise',
                        value: null
                    })
                })

            commit('setObjectField', {
                object: state,
                objectPath: 'flowsState.getBoardFlowsPromise',
                value: getBoardFlowsPromise
            })

            return getBoardFlowsPromise
        }

        return state.flowsState.getBoardFlowsPromise
    },
    createFlow({ commit, state }: { commit: Commit, state: RootState }, {
        title,
        scheme
    }: { title: string, scheme: any }): Promise<FlowItem> {
        return new Promise(resolve => {
            FlowsService.createFlow('tg', { board_key: state.boardsState.activeBoard!.board, title, scheme })
                .then((flow) => {
                    commit('SET_NEW_FLOW_TO_STORE', Object.assign({ title, flow }))
                    resolve(flow)
                })
                .catch(errorNotification)
        })
    },

    removeFlow({ commit, state }: { commit: Commit, state: RootState }, flow_key: string): Promise<DeleteFlow> {
        return new Promise(resolve => {
            FlowsService.deleteFlow('tg', { board_key: state.boardsState.activeBoard!.board, flow_key })
                .then((res) => {
                    commit('REMOVE_FLOW_FROM_STORE', `${ res.key.token_id }.${ res.key.token_code }`)
                    resolve(res)
                })
                .catch(errorNotification)
        })
    },

    renameFlow({ commit, state }: { commit: Commit, state: RootState }, {
        title,
        key
    }: { title: string, key: string }) {
        return new Promise(resolve => {
            FlowsService.renameFlow('tg', { board_key: state.boardsState.activeBoard!.board, title, key })
                .then((res) => {
                    commit('RENAME_FLOW', { title, flow_key: key })
                    successNotification(i18n.t('flow_name_successfully_updated').toString())
                    resolve(res)
                })
        })
    },
    async createFlowTemplate({
                                 commit,
                                 state,
                                 getters,
                                 dispatch
                             }: { commit: Commit, state: RootState, getters: any, dispatch: Dispatch }, flowTemplate: CreateTemplateFlowRequestBody) {
        try {
            const { flow } = await FlowsService.createTemplateFlow('tg', flowTemplate)
            const boardId = TokenId.getId(flowTemplate.board_key)

            if (!getters.isBoardSet) {
                await dispatch('getBoardConfig', flowTemplate.board_key)
            }

            const query = !state.flowsState.flows?.length ? {
                onboarding: 'show',
            } : {}

            router.push({
                name: 'Flow',
                params: {
                    [EntityTypes.BOT_ID]: boardId,
                    actionType: 'edit'
                },
                query: {
                    flow_key: flow.key,
                    ...query
                }
            })
        } catch (e) {
            errorNotification(e)
        }
    }
}
