var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"change-activity-settings"},[_c('number-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'value',
        'prefix': ("integration_" + _vm.type + "_"),
        'validation': 'required',
      }
    }}}),_c('number-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'lower_limit',
        'prefix': "integration_",
        'validation': 'required',
        'settingWrapper': {
          customiseValue: 0,
          hasFieldButton: true,
          disabledValue: null,
          buttonTitle: _vm.$t('customise')
        },
      }
    }}}),_c('number-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'upper_limit',
        'prefix': "integration_",
        'validation': 'required',
        'settingWrapper': {
          customiseValue: 0,
          hasFieldButton: true,
          disabledValue: null,
          buttonTitle: _vm.$t('customise')
        },
      }
    }}}),_c('text-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'reason',
        'prefix': 'integration_',
        'settingWrapper': {
          customiseValue: '',
          hasFieldButton: true,
          disabledValue: '',
          buttonTitle: _vm.$t('customise')
        },
      },
    }}}),(_vm.model instanceof _vm.ChangePointsRequest)?[_c('time-unit-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.model,
          'key': 'timeout',
          'prefix': 'integration_',
          'settingWrapper': {
            customiseValue: 1,
            hasFieldButton: true,
            disabledValue: 0,
          },
        }
      }}}),(_vm.model.timeout > 0)?_c('nested-content',[_c('switch-input',{attrs:{"setup":{
          'func': _vm.modelSetter,
          'args': {
            'model': _vm.model,
            'key': 'disable_negative',
            'prefix': 'integration_',
          },
        }}})],1):_vm._e()]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }