import buttonView from './LimitedSubscriptionLinkButton.vue'
import setupView from './LimitedSubscriptionLinkButtonSetupView.vue'
import { ProjectButtonTypes } from '@/components/ProjectButtons/ProjectButtonTypes'
import { HiddenTargetType } from '@/components/ProjectButtons/includes/helpers/HiddenTargetTypeOptions'
import i18n from '@/i18n/i18n'

import BaseButtonBuilder, {
  BaseButtonViewData
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons'
import {
  BaseButton
} from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/types'
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'

import VueI18n from 'vue-i18n'
import { plainToInstance } from 'class-transformer'
import { Guid } from 'guid-typescript'

export default class LimitedSubscriptionLinkButtonBuilder extends BaseButtonBuilder {
  type = ProjectButtonTypes.LimitedSubscriptionLinkButton

  titleKey = ProjectButtonTypes.LimitedSubscriptionLinkButton

  setupView = setupView
  buttonView = buttonView

  icon = '⏲️👆'

  data = new LimitedSubscriptionLinkButtonData(this.$i18n.t('initial_text_message_editor_buttons').toString())

  constructor(i18n: VueI18n, getArticlesFn: () => Promise<Array<SelectOptionData>>, getHasChannelsContentSubscription: () => boolean) {
    super(i18n, { getArticlesFn, getHasChannelsContentSubscription })
  }

  getData(button?: LimitedSubscriptionLinkButton): LimitedSubscriptionLinkButtonData {
    return new LimitedSubscriptionLinkButtonData(button ? button : this.$i18n.t('initial_text_message_editor_buttons').toString())
  }

  create(): LimitedSubscriptionLinkButton {
    return plainToInstance(LimitedSubscriptionLinkButton, {
      ...this.data,
      type: ProjectButtonTypes.LimitedSubscriptionLinkButton,
      guid: Guid.create().toString(),
    })
  }
}

export class LimitedSubscriptionLinkButtonData implements BaseButtonViewData {
  subscribedTargetType = HiddenTargetType.Message
  subscribedTargetValue = i18n.t('initial_text_message_editor_limited_subscription_subscribed').toString()
  unsubscribedTargetType = HiddenTargetType.Message
  unsubscribedTargetValue = i18n.t('initial_text_message_editor_limited_subscription_unsubscribed').toString()
  text = ''

  constructor(data: LimitedSubscriptionLinkButton | string) {
      if (data instanceof LimitedSubscriptionLinkButton || typeof data === 'object') {
        const {
          subscribedTargetType,
          subscribedTargetValue,
          unsubscribedTargetType,
          unsubscribedTargetValue,
          text,
        } = data

        this.subscribedTargetType = subscribedTargetType,
        this.subscribedTargetValue = subscribedTargetValue,
        this.unsubscribedTargetType = unsubscribedTargetType,
        this.unsubscribedTargetValue = unsubscribedTargetValue,
        this.text = text
      } else {
        this.text = data
      }
  }
}

export class LimitedSubscriptionLinkButton implements BaseButton {
  text!: string
  type!: ProjectButtonTypes.LimitedSubscriptionLinkButton
  guid!: string
  subscribedTargetType!: HiddenTargetType
  subscribedTargetValue!: string
  unsubscribedTargetType!: HiddenTargetType
  unsubscribedTargetValue!: string
}
