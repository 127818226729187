import setupView from './RunFlowButtonSetupView.vue'
import buttonView from './RunFlowButtonView.vue'
import { ProjectButtonTypes } from '@/components/ProjectButtons/ProjectButtonTypes'

import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import BaseButtonBuilder, { BaseButtonViewData }  from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/index'
import { BaseButton, } from 'piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/types'
import { SelectInputOptions } from 'piramis-base-components/src/components/Pi/fields/SelectInput/types'

import { Guid } from 'guid-typescript'
import { plainToInstance } from 'class-transformer'
import i18n from 'vue-i18n'

interface Props {
  getFlowsOptions: () => Promise<Array<SelectOptionData>>
  getUtmSourcesOptions?: () => Promise<Array<SelectOptionData>>
}

export default class RunFlowButtonBuilder extends BaseButtonBuilder {
  type = ProjectButtonTypes.RunFlowButton

  titleKey = ProjectButtonTypes.RunFlowButton

  setupView = setupView
  buttonView = buttonView

  icon = '🎬'

  data = new RunFlowButtonData(this.$i18n.t('initial_text_message_editor_buttons').toString())

  declare buttonProps: Props

  constructor(i18n: i18n, getFlowsOptions: SelectInputOptions, getUtmSourcesOptions?: SelectInputOptions) {
    super(i18n, { getFlowsOptions, getUtmSourcesOptions })
  }

  getData(button?: RunFlowButton): RunFlowButtonData {
    return new RunFlowButtonData(button ? button : this.$i18n.t('initial_text_message_editor_buttons').toString())
  }

  create(): RunFlowButton {
    return plainToInstance(RunFlowButton, {
      ...this.data,
      type: ProjectButtonTypes.RunFlowButton,
      guid: Guid.create().toString()
    })
  }
}

export class RunFlowButtonData implements BaseButtonViewData {
  flow: string | null = null
  utm: string | null  = null
  text: string = ''

  constructor(data: RunFlowButton | string) {
    if(data instanceof RunFlowButton || typeof data === 'object') {
      const { flow, utm, text } = data

      this.flow = flow
      this.utm = utm
      this.text = text
    } else {
      this.text = data
    }
  }
}

export class RunFlowButton implements BaseButton {
  text!: string
  type!: ProjectButtonTypes.RunFlowButton
  guid!: string
  flow!: string
  utm!: string
}