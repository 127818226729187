






















































































import {
  BaseFieldData,
  FormFields,
  FormFieldType,
  FrontendFormField,
} from '@/includes/types/Form.types'
import { InputSetups } from '@/mixins/input-setups'
import FormField from './FormFieldConstructor.vue'
import {
  formFieldsModel,
  getFieldTemplate,
  prepareFormForCreate,
  prepareFrontendFields
} from '@/components/Forms/logic/FormsHelper'
import FormFieldConstructor from '@/components/Forms/FormFieldConstructor.vue'
import FillFormField from './components/FillFormField.vue'

import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { IterableListItem } from 'piramis-base-components/src/shared/utils/IterableItemFactory'
import AddEntityCard from "piramis-base-components/src/components/AddEntityCard.vue";

import { Component, Emit, Mixins, Prop, Ref, Watch } from 'vue-property-decorator'
import arrayMove from 'array-move'
import { cloneDeep } from 'lodash'

@Component({
  components: {
    FormFieldConstructor,
    AddEntityCard,
    EmptyData,
    FormField,
    FillFormField
  }
})
export default class FieldsList extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @Prop({ type: Array }) fields!:Array<FormFields>

  @Prop({ type: Boolean }) disabled!: boolean

  @Ref('fields-list') listContainer!: HTMLElement

  @Emit('updateFields')
  updateFields(fields: Array<Required<BaseFieldData>>):Array<Required<BaseFieldData>> {
    return fields
  }

  @Watch('frontendFields', { deep: true })
  onFrontendFieldsChange(newFields:Array<IterableListItem<FrontendFormField>>):void {
    this.updateFields(prepareFormForCreate(newFields))
  }

  frontendFields = this.iterateeFields

  get iterateeFields() {
    return prepareFrontendFields(this.fields)
  }

  get selectedProperties():Array<number> {
    if (this.frontendFields) {
      return this.frontendFields
        .filter(f => f.value.config.property_id !== null)
        .map(f => f.value.config.property_id!)
    }

    return []
  }

  addFieldTemplate():void {
    this.frontendFields.push(getFieldTemplate())

    if (this.listContainer.offsetHeight !== this.listContainer.scrollHeight) {
      this.$nextTick(() => {
        this.listContainer.scroll({ behavior: 'smooth', top: this.listContainer.scrollHeight })
      })
    }
  }

  removeField(guid:string):void {
    this.frontendFields = this.frontendFields.filter(field => field.guid !== guid)
  }

  handleTypeUpdate(type: FormFieldType, guid:string):void {
    const item = this.frontendFields.find(f => f.guid === guid)
    const itemIndex = this.frontendFields.findIndex(f => f.guid === guid)

    if (item) {
      const typeModel = cloneDeep(formFieldsModel[type])

      this.$set(item.value, 'config', typeModel.config)
      this.$set(item.value, 'model', typeModel.model)

      this.frontendFields.splice(itemIndex, 1, item)
    }

  }

  movePosition(array:Array<IterableListItem<FrontendFormField>>, currIndex:number, newIndex:number) {
    const ref = this.$refs[`list-item-${ array[currIndex].guid }`] as Array<HTMLElement>
    this.frontendFields = arrayMove(array, currIndex, newIndex)

    if (ref && ref.length) {
      this.$nextTick(() => {
        const item = ref[0]
        this.listContainer.scroll({ behavior: 'smooth', top: item.offsetTop - this.listContainer.offsetTop })
      })
    }
  }

  mounted():void {
    if (!this.fields.length) {
      this.addFieldTemplate()
    }
  }
}
