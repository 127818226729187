import BaseApi from '@/includes/services/BaseApi'
import {
    AnsweredUser,
    Form,
    FormAnswer,
    FormAnswerRequest,
    FormAnswersRequest, ILastFormAnswer,
    TestFormRequest
} from '@/includes/types/Form.types'
import { BaseBoardRequest } from "@/includes/types/Api.types";

type ResponseForm = { form: Required<Form> }

export class FormService {
    static async createForm(type: string, body: BaseBoardRequest<Form>) {
        return BaseApi.sendRequest<ResponseForm>(type, 'createform', body)
    }

    static async getForms(type: string, body: BaseBoardRequest) {
        return BaseApi.sendRequest<{ items: Array<Required<Form>> }>(type, 'getforms', body)
    }

    static async getForm(type: string, body: BaseBoardRequest<{ key: string }>) {
        return BaseApi.sendRequest<ResponseForm>(type, 'getform', body)
    }

    static async deleteForm(type: string, body: BaseBoardRequest<{ key: string }>) {
        return BaseApi.sendRequest<ResponseForm>(type, 'deleteform', body)
    }

    static async createFormAnswer(type:string, body: FormAnswerRequest) {
        return BaseApi.sendRequest<{url: string}>(type, 'createformanswer', body)
    }

    static async testForm(type:string, body: TestFormRequest) {
        return BaseApi.sendRequest<{result: string}>(type, 'testform', body)
    }

    static async updateForm(type:string, body: BaseBoardRequest<Form>) {
        return BaseApi.sendRequest<ResponseForm>(type, 'updateform', body)
    }

    static async getFormAnswers(type:string, body: FormAnswersRequest) {
        return BaseApi.sendRequest<{ items: Array<FormAnswer> }>(type, 'getformanswers', body)
    }

    static async getQuestionnaire(type:string, body: { token: string }) {
        return BaseApi.sendRequest<{ form: Required<Form>, user: AnsweredUser, board_key: string }>(type, 'getquestionnaire', body)
    }

    static async getLastFormAnswer(type:string, body: { token: string }) {
        return BaseApi.sendRequest<ILastFormAnswer>(type, 'getlastformanswer', body)
    }

}