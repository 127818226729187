






































































































































import { TargetFaceControl } from '@/includes/logic/FaceControl/TargetFaceControl'
import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import SaveChannelConfig from '@/components/SaveChannelConfig.vue'
import AddFaceControlCondition from '@/components/FaceControl/AddFaceControlCondition.vue'
import { InputSetups } from '@/mixins/input-setups'
import { errorNotification, warningNotification } from '@/includes/services/NotificationService'
import { FaceControlConditions } from '@/includes/logic/FaceControl/FaceControlConditions'
import InitialiseConditions from '@/components/FaceControl/InitialiseConditions.vue'
import { FaceControlMode } from '@/includes/types/FaceControl.types'

import List from 'piramis-base-components/src/components/Pi/components/List/List.vue'
import BlockMovingControls from "piramis-base-components/src/components/BlockMovingControls/BlockMovingControls.vue";
import { SelectOptionData } from 'piramis-base-components/src/components/Pi/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import { IterableListItem } from "piramis-base-components/src/shared/utils/IterableItemFactory";
import AddEntityCard from "piramis-base-components/src/components/AddEntityCard.vue";

import { plainToInstance } from 'class-transformer'
import { Component, Mixins } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: {
    InitialiseConditions,
    AddFaceControlCondition,
    SaveChannelConfig,
    PageTitle,
    CenteredColumnLayout,
    List,
    BlockMovingControls,
    AddEntityCard,
    EmptyData
  }
})
export default class FaceControl extends Mixins(UseFields, InputSetups) {

  faceControl: TargetFaceControl | null = null

  get faceControlModeOptions(): Array<SelectOptionData> {
    return [
      {
        label: this.$t('face_control_mode_accept').toString(),
        value: FaceControlMode.Accept
      },
      {
        label: this.$t('face_control_mode_reject').toString(),
        value: FaceControlMode.Reject
      },
    ]
  }

  getConditions() {
    const channel = this.$store.state.channelsState.activeChannel

    if (channel) {
      const config = channel.config

      this.faceControl = plainToInstance(TargetFaceControl, config?.face_control ?? null)
    }
  }

  saveChannelConfig() {
    this.prepareForSave()
      .then(() => {
        this.$store.commit('pi/EXEC', {
          'fn': () => {
            this.$store.state.channelsState.activeChannel!.config.face_control = this.faceControl?.toPlain() ?? null
          },
        })

        this.$store.dispatch('save_active_channel_config', this.$route)
      })
      .catch((error:any) => {
        if (error && error.value && error.value instanceof FaceControlConditions) {
          this.conditionCardError(error as IterableListItem<FaceControlConditions>)
        } else if (Array.isArray(error.value) && !error.value.length) {
          this.conditionGroupCardError(error as IterableListItem<Array<IterableListItem<FaceControlConditions>>>)
        } else {
          errorNotification(error)
        }
      })
  }

  conditionCardError(condition: IterableListItem<FaceControlConditions>) {
    const ref = this.$refs[`condition-${ condition.guid }`]

    warningNotification(this.$t('face_control_condition_error', [ condition.value.title ]).toString())
    this.highlightCard(ref)
  }

  conditionGroupCardError(group: IterableListItem<Array<IterableListItem<FaceControlConditions>>>) {
    const ref = this.$refs[`condition-group-${ group.guid }`]

    warningNotification(this.$t('face_control_condition_group_empty_error').toString())
    this.highlightCard(ref)
  }

  highlightCard(ref:  Vue | Element | (Vue | Element)[] | undefined) {
    if (ref) {
      const el = ref[0].$el as HTMLDivElement

      el.scrollIntoView({ behavior: 'smooth' })
      el.classList.add('border-2')
      el.classList.add('border-red-600')

      setTimeout(() => {
        el.classList.remove('border-2')
        el.classList.remove('border-red-600')
      }, 3000)
    }
  }

  async prepareForSave() {
    if (this.faceControl !== null) {
      return this.faceControl.validateConditions()
    } else {
      return Promise.resolve()
    }

  }

  get toggleFaceControlButtonTitle() {
    if (this.faceControl) {
      if (this.faceControl.active) {
        return this.$t('disable_face_control_button_title').toString()
      } else {
        return this.$t('activate_face_control_button_title').toString()
      }
    }
  }

  get toggleFaceControlButtonType() {
    if (this.faceControl) {
      if (this.faceControl.active) {
        return 'danger'
      } else {
        return 'primary'
      }
    }  }

  toggleFaceControl() {
    if (this.faceControl) {
      this.faceControl.active = !this.faceControl.active
      warningNotification(this.$t('face_control_toggle_save_setting_warn').toString())
    }
  }

  created() {
    this.getConditions()
  }
}
