import { ChatkeeperIntegrationRequest } from "../IntegrationRequest/ChatkeeperIntegrationRequest";
import { ChatkeeperRequestTypeEnum } from "../types";

import { plainToInstance } from "class-transformer";

export class ChangeReputationRequest extends ChatkeeperIntegrationRequest {
  type: ChatkeeperRequestTypeEnum = ChatkeeperRequestTypeEnum.ChangeReputation;

  value!: number

  lower_limit!: number
  upper_limit!: number

  reason!: string
}
